import { useCallback, useEffect, useState } from 'react';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
// @mui
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// routes
// _mock
// hooks
// components
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableNoData,
  TablePaginationCustom,
  useTable
} from 'src/components/table';
import moment from 'moment';
//
import { INVOICE_SERVICE_OPTIONS } from 'src/_mock';
import Iconify from 'src/components/iconify';
import useStores from 'src/hooks/use-stores';
import ChannelTableFiltersResult from 'src/sections/instance/channel-table-filters-result';
import MetricsTableHeader from '../metrics-table-header';
import MetricsTableRow from '../metrics-table-row';
import MetricsTableToolbar from '../metrics-table-toolbar';
import { enqueueSnackbar } from 'notistack';
import { useLocales } from 'src/locales';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'store', label: 'Merchant', grouped: 'Información del merchant' },
  { id: 'country', label: 'País', grouped: 'Información del merchant' },
  { id: 'total_conversations', label: 'Total Conv.', grouped: 'Conversaciones', helpText: 'Cantidad total de conversaciones' },
  { id: 'total_conversations_ai', label: 'Conv. con IA', grouped: 'Conversaciones', helpText: 'Cantidad de conversaciones con participación de la AI (al menos un mensaje del bot)' },
  { id: 'ia_participation', label: 'Part. IA', grouped: 'Conversaciones', helpText: 'Porcentaje de participación de IA en las conversaciones' },
  { id: 'full_ia_conversations', label: 'Conv. Full IA', grouped: 'Conversaciones', helpText: 'Cantidad de conversaciones gestionadas completamente por la AI (al menos un mensaje del bot y ningún mensaje del merchant)' },
  { id: 'ia_participation_rate', label: 'Gestión 100% IA', grouped: 'Conversaciones', helpText: 'Porcentaje de conversaciones gestionadas completamente por IA en relacion al total de conversaciones con IA' },
  { id: 'ia_merchant_conversations', label: 'IA + Merchant', grouped: 'Conversaciones', helpText: 'Cantidad de conversaciones iniciadas por la AI e intervenidas por el merchant (al menos un mensaje del bot y del merchant)' },
  { id: 'percentage_merchant_intervention', label: '% Interv. merchant', grouped: 'Conversaciones', helpText: 'Porcentaje de intervención del merchant en conversaciones gestionadas en parte por IA' },
  { id: 'percentage_full_IA', label: '% Full IA', grouped: 'Conversaciones', helpText: 'Porcentaje de conversaciones gestionadas completamente por IA en relación al total de conversaciones' },
  { id: 'actions', label: '', grouped: '', width: '80px' },
];



const defaultFilters = {
  name: '',
  service: [],
  status: 'all',
  startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
  endDate: new Date(),
  country: '',
  billingPlan: '',
};
// ----------------------------------------------------------------------

export default function MetricsListView() {
  const table = useTable({ defaultOrderBy: 'id' });

  const settings = useSettingsContext();

  const [tableData, setTableData] = useState<any[]>([]);
  const [stores] = useStores({ light: true });
  const [filters, setFilters] = useState(defaultFilters);
  const [isLoading, setIsLoading] = useState(false);

  const [downloading, setDownloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [total, setTotal] = useState(0);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const denseHeight = table.dense ? 52 : 72;
  const { t } = useLocales();


  const canReset =
    !!filters.name ||
    !!filters.service.length ||
    filters.status !== 'all' ||
    (!!filters.startDate && !!filters.endDate);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;


  const handleFilters = useCallback(

    (name: string, value: any) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  useEffect(() => {
    setCurrentPage(0);
    setTableData([]);
    setIsLoading(true);
    getChannelList();
  }, [filters])
  useEffect(() => {
    if (currentPage > 0) {
      getChannelList();
    }
  }, [currentPage])

  const getChannelList = useCallback(() => {
    const formatDate = (date: Date) => date ? new Date(date).toISOString().split('T')[0] : '';
    const queryParams = [
      `page=${currentPage}&length=25`,
      filters.billingPlan ? `billingPlan=${filters.billingPlan}` : '',
      filters.country ? `country=${filters.country}` : '',
      filters.name ? `search=${filters.name}` : '',
      filters.startDate && filters.endDate ? `since=${formatDate(filters.startDate)}&to=${formatDate(filters.endDate)}` : '',
    ].filter(Boolean).join('&');

    const diffInDays = moment(filters.endDate).diff(moment(filters.startDate), 'days');
    if (diffInDays > 365) {
      setIsLoading(false);
      enqueueSnackbar('La diferencia entre fechas no puede ser mayor a 365 días', { variant: 'warning' });
      return;
    }

    const URL = API_ENDPOINTS.dashboard.stats(queryParams ? `?${queryParams}` : '');
    axios
      .get(URL)
      .then(({ data }: any) => {
        setTableData(data.rows);
        setTotal(data.total);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(t(error.error), { variant: 'error' });
      })
  }, [filters, currentPage]);

  const downloadReport = () => {
    setDownloading(true);
    const formatDate = (date: Date) => date ? new Date(date).toISOString().split('T')[0] : '';
    const queryParams = [
      filters.billingPlan ? `plan_name=${filters.billingPlan}` : '',
      filters.country ? `country=${filters.country}` : '',
      filters.name ? `search=${filters.name}` : '',
      filters.startDate && filters.endDate ? `since=${formatDate(filters.startDate)}&to=${formatDate(filters.endDate)}` : '',
    ].filter(Boolean).join('&');
    axios
      .get(API_ENDPOINTS.dashboard.downloadReport(queryParams ? `?${queryParams}` : ''), { responseType: 'blob' })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        const startDate = filters.startDate ? new Date(filters.startDate).toISOString().split('T')[0] : 'inicio';
        const endDate = filters.endDate ? new Date(filters.endDate).toISOString().split('T')[0] : 'fin';
        link.setAttribute('download', `reporte_métricas_${startDate}_a_${endDate}.csv`); // or any other extension
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
      })
      .catch((error) => {
        setDownloading(false);
        enqueueSnackbar(t(error.error), { variant: 'error' });
      });
  };

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>



        <Card>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ py: 2, pr: 1, pl: 2.5 }}
          >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Métricas conversaciones
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={downloadReport}
              sx={{ ml: 'auto' }}
              disabled={downloading}
              startIcon={<Iconify icon="icon-park-outline:excel" />}
            >
              {downloading ? 'Descargando...' : 'Descargar reporte'}
            </Button>
          </Stack>
          <MetricsTableToolbar
            filters={filters}
            onFilters={handleFilters}
            //
            serviceOptions={INVOICE_SERVICE_OPTIONS.map((option) => option.name)}
          />
          {canReset && (
            <ChannelTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={dataFiltered.length}
              storeOptions={stores}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            sx={{ pr: 5, pl: 2.5 }}
          >
            {isLoading && <CircularProgress size={48} color="info" />}
          </Stack>
          {!isLoading && (
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>

              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'}>
                  <MetricsTableHeader
                    headLabel={TABLE_HEAD}
                  />

                  <TableBody>
                    {dataFiltered
                      .slice(
                        table.page * table.rowsPerPage,
                        table.page * table.rowsPerPage + table.rowsPerPage
                      )
                      .map((row) => (
                        <MetricsTableRow
                          key={row.id}
                          row={row}
                        />
                      ))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                    />

                    {dataFiltered.length === 0 && <TableNoData notFound={notFound} />}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          )}

          <TablePaginationCustom
            count={total}
            page={currentPage}
            rowsPerPage={25}
            onPageChange={(event, newPage) => {
              setCurrentPage(newPage);
            }}
            rowsPerPageOptions={[]}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters
}: {
  inputData: any[];
  comparator: (a: any, b: any) => number;
  filters: any;
}) {
  const { store, status } = filters;
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  inputData = stabilizedThis.map((el) => el[0]);

  // if (status !== 'all') {
  //   inputData = inputData.filter((instance) => instance.state?.name === status);
  // }




  return inputData;
}
