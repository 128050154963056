import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/system/Stack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Carousel, { CarouselArrowIndex, useCarousel } from 'src/components/carousel';
import FormProvider, {
  RHFCheckbox,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFUpload,
} from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { LoadingScreen } from 'src/components/loading-screen';
import { useSnackbar } from 'src/components/snackbar';
import { Upload } from 'src/components/upload';
import { useResponsive } from 'src/hooks/use-responsive';
import useStoreCategories from 'src/hooks/use-stores copy';
import { useLocales } from 'src/locales';
import { RouterLink } from 'src/routes/components';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import { IStoreItem } from 'src/types/store';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
// utils
import { fDate } from 'src/utils/format-time';
import * as Yup from 'yup';
import Image from 'src/components/image';
import { Mixpanel } from 'src/Mixpanel';
import { CheckBox } from '@mui/icons-material';

// Table Simple

type Props = {
  currentStore?: IStoreItem;
  updatedStoreData: VoidFunction;
};
type FormValuesProps = any;

export default function StoreFlowMessages({ currentStore, updatedStoreData }: any) {
  const mdUp = useResponsive('up', 'md');
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [currentConfiguration, setCurrentConfiguration] = useState<any>();
  const { t } = useLocales();
  const [workflows, setWorkflows] = useState([]);
  const [currentWorkflow, setCurrentWorkflow] = useState(
    currentStore?.orderStateWorkflow?.id || ''
  );

  const defaultValues = useMemo(
    () => ({
      state_created: currentConfiguration?.state_created || '',
      state_accepted: currentConfiguration?.state_accepted || '',
      state_finalized: currentConfiguration?.state_finalized || '',
      state_ready_to_pickup: currentConfiguration?.state_ready_to_pickup || '',
      state_done: currentConfiguration?.state_done || '',
      state_rejected: currentConfiguration?.state_rejected || '',
      state_sent: currentConfiguration?.state_sent || '',
      welcome_message: currentStore?.welcome_message || '',
      welcome_message_check: !!currentStore?.welcome_message || false,
      closed_message_check: !!currentStore?.closed_message || false,
      closed_message: currentStore?.closed_message || '',
      closed_paused_message_check: !!currentStore?.closed_paused_message || false,
      closed_paused_message: currentStore?.closed_paused_message || '',
      resume_observation: currentStore?.resume_observation || '',
      order_state_workflow: currentWorkflow,
      notifications: currentStore?.notifications,
    }),
     
    []
  );
  const methods = useForm<FormValuesProps>({
    defaultValues,
    mode: 'onBlur',
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,    
    watch,
    trigger,
    getValues,
  } = methods;
  const values = watch();

  useEffect(() => {    
    console.log('store', currentStore);
    const defaults = {
      welcome_message: currentStore?.welcome_message || '',
      welcome_message_check: !!currentStore?.welcome_message || false,
      closed_message_check: !!currentStore?.closed_message || false,
      closed_message: currentStore?.closed_message || '',
      closed_paused_message_check: !!currentStore?.closed_paused_message || false,
      closed_paused_message: currentStore?.closed_paused_message || '',
      resume_observation: currentStore?.resume_observation || '',
      order_state_workflow: currentWorkflow,
      notifications:  currentStore?.notifications,
    };
    Object.entries(defaults).forEach(([fieldName, value]) => {
      setValue(fieldName, value);
    });
  }, [currentStore, setValue, currentWorkflow]);
  
  useEffect(() => {    
    console.log('store config', currentConfiguration);
    const defaults = {
      state_created: currentConfiguration?.state_created || '',
      state_accepted: currentConfiguration?.state_accepted || '',
      state_finalized: currentConfiguration?.state_finalized || '',
      state_ready_to_pickup: currentConfiguration?.state_ready_to_pickup || '',
      state_done: currentConfiguration?.state_done || '',
      state_rejected: currentConfiguration?.state_rejected || '',
      state_sent: currentConfiguration?.state_sent || '',
    };
    Object.entries(defaults).forEach(([fieldName, value]) => {
      setValue(fieldName, value);
    });
  }, [currentConfiguration, setValue]);

  const editStore = useCallback(
    async (id: string, params: any) => {
      axios
        .put(API_ENDPOINTS.storeConfiguration.edit(currentStore.id), params)
        .then((val) => {
          setLoading(false);
          enqueueSnackbar('Configuración actualizada');
        })
        .catch((err) => {
          setLoading(false);
          enqueueSnackbar('Hubo un error', { variant: 'error' });
        });
    },
    [currentStore, enqueueSnackbar]
  );
  const updateStoreField = async (fieldName: string) => {
    const fieldValue = getValues(fieldName);
    await trigger(fieldName);    
    axios
      .patch(API_ENDPOINTS.store[fieldName](currentStore?.id), { data: fieldValue })
      .then((val) => {
        enqueueSnackbar('Configuración actualizada');
        Mixpanel.track('Store Customization', { id: currentStore.id, fields: fieldName });
        if (fieldName === 'order_state_workflow') {
          getConfiguration();
          setCurrentWorkflow(fieldValue);
        }       
        updatedStoreData();       
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSubmit = useCallback(
    async (params: FormValuesProps) => {
      setLoading(true);
      const filteredKeys = Object.keys(params).filter((key) =>
        Object.prototype.hasOwnProperty.call(currentConfiguration, key)
      );

      // Crear un nuevo objeto con las claves filtradas
      const auxObject: any = {};
      filteredKeys.forEach((key) => {
        auxObject[key] = params[key];
      });
      editStore(currentStore.id, auxObject);
    },
    [currentStore, editStore, currentConfiguration]
  );
  const getConfiguration = useCallback(() => {
    axios
      .get(API_ENDPOINTS.storeConfiguration.list(currentStore?.id))
      .then(({ data }) => {
        setCurrentConfiguration(data);      
      })
      .catch((error) => {});
  }, [currentStore]);

  useEffect(() => {
    getConfiguration();
  }, [currentStore, getConfiguration]);
  const getWorkflow = () => {
    axios
      .get(API_ENDPOINTS.workflow.list)
      .then(({ data }) => {
        setWorkflows(data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    getWorkflow();
  }, []);
  const renderStateMessages = (
    <>
      <Grid xs={12} sm={6}>
        {currentConfiguration ? (
          <Card>
            {!mdUp && <CardHeader title="Flujo de estados" />}

            <Stack spacing={3} sx={{ p: 3 }}>
              <Typography variant="subtitle2">
                Configura mensajes automáticos para los cambios de estado de pedidos
              </Typography>
              {/*
              {'state_created' in currentConfiguration && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('state_created')}</Typography>
                  <RHFTextField
                    name="state_created"
                    helperText="Ingrese el mensaje que desea enviar cuando el pedido es creado"
                  />
                </Stack>
              )}
              */}
              <RHFCheckbox              
                labelPlacement="start"                
                name='notifications'
                label="Notificar cambio de estado en el pedido al cliente"
                onClick={() => {                  
                  updateStoreField('notifications');                 
                }}
              />                        
              <RHFSelect
                label="Cantidad de pasos por pedido"
                InputLabelProps={{ shrink: watch().order_state_workflow?.value }}
                name="order_state_workflow"
                onBlur={() => updateStoreField('order_state_workflow')}
              >
                {workflows.map((workflow: any) => (
                  <MenuItem key={workflow?.id} value={workflow?.id}>
                    {workflow?.description}
                  </MenuItem>
                ))}
              </RHFSelect>
              {watch().notifications && <>            
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">Mensaje de orden confirmada</Typography>
                <RHFTextField
                  name="resume_observation"
                  placeholder="Ingrese un mensaje de orden confirmada"
                  multiline
                  rows={3}
                  onBlur={() => updateStoreField('resume_observation')}                  
                />
              </Stack>
              {'state_accepted' in currentConfiguration && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('state_accepted')}</Typography>
                  <RHFTextField
                    name="state_accepted"
                    helperText="Ingrese el mensaje que desea enviar cuando el pedido es aceptado"
                    onBlur={() => updateStoreField('state_accepted')}
                  />
                </Stack>
              )}
              {'state_done' in currentConfiguration && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('state_done')}</Typography>
                  <RHFTextField
                    name="state_done"
                    helperText="Ingrese el mensaje que desea enviar cuando el pedido está hecho"
                    onBlur={() => updateStoreField('state_done')}
                  />
                </Stack>
              )}
              {'state_ready_to_pickup' in currentConfiguration && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('state_ready_to_pickup')}</Typography>
                  <RHFTextField
                    name="state_ready_to_pickup"
                    helperText="Ingrese el mensaje que desea enviar cuando el pedido está listo para retirar"
                    onBlur={() => updateStoreField('state_ready_to_pickup')}
                  />
                </Stack>
              )}
              {'state_sent' in currentConfiguration && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('state_sent')}</Typography>
                  <RHFTextField
                    name="state_sent"
                    helperText="Ingrese el mensaje que desea enviar cuando el pedido es enviado"
                    onBlur={() => updateStoreField('state_sent')}
                  />
                </Stack>
              )}
              {'state_finalized' in currentConfiguration && (
                <Stack spacing={1.5}>
                  <Typography variant="subtitle2">{t('state_finalized')}</Typography>
                  <RHFTextField
                    name="state_finalized"                   
                    helperText="Ingrese el mensaje que desea enviar cuando el pedido está finalizado"
                    onBlur={() => updateStoreField('state_finalized')}
                  />
                </Stack>
              )}
              </>}
            </Stack>
          </Card>
        ) : (
          <>
            <Stack spacing={2}>
              <Skeleton variant="rounded" width="100%" height={120} />
              <Skeleton variant="rounded" width="100%" height={120} />
              <Skeleton variant="rounded" width="100%" height={120} />
            </Stack>
          </>
        )}
      </Grid>
    </>
  );
  const renderBotMessages = (
    <>
      <Grid xs={12} sm={6}>
        {currentStore && (
          <Card>
            {!mdUp && <CardHeader title="Mensajes automáticos" />}

            <Stack spacing={3} sx={{ p: 3 }}>
              <Typography variant="subtitle2">
                Configura mensajes automáticos para tus clientes
              </Typography>
              <Stack spacing={1.5}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2" alignContent="center">
                    Mensaje de bienvenida
                  </Typography>
                </Stack>
                <RHFTextField
                  name="welcome_message"
                  placeholder="Ingrese un mensaje de bienvenida"
                  multiline
                  rows={3}
                  onBlur={() => updateStoreField('welcome_message')}
                />
              </Stack>
              <Stack spacing={1.5}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2" alignContent="center">
                    Mensaje de cierre
                  </Typography>
                </Stack>
                <RHFTextField
                  name="closed_message"
                  placeholder="Ingrese un mensaje de cierre"
                  multiline
                  rows={3}
                  onBlur={() => updateStoreField('closed_message')}
                />
              </Stack>
              <Stack spacing={1.5}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2" alignContent="center">
                    Mensaje de cierre temporal
                  </Typography>
                </Stack>
                <RHFTextField
                  name="closed_paused_message"
                  placeholder="Ingrese un mensaje de cierre temporal"
                  multiline
                  rows={3}
                  onBlur={() => updateStoreField('closed_paused_message')}
                />
              </Stack>
            </Stack>
          </Card>
        )}
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {renderBotMessages}
        {renderStateMessages}
      </Grid>

      {/* 
      <Box sx={{ display: 'flex', mt: 3 }}>

        <Box sx={{ flexGrow: 1 }} />
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isLoading}
          sx={{ ml: 2 }}
        >
          Guardar cambios
        </LoadingButton>

      </Box>
     */}
    </FormProvider>
  );
}
