import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { IProduct } from 'src/types/product';
// routes
// _mock
import { USER_STATUS_OPTIONS } from 'src/_mock';
// types
import { IUserItem, IUserRole } from 'src/types/user';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { CustomFile } from 'src/components/upload';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
  RHFMultiSelect,
} from 'src/components/hook-form';
import { IStoreType } from 'src/types/store';
import useStores from 'src/hooks/use-stores';
import useRoles from 'src/hooks/use-roles';
import useUserStates from 'src/hooks/use-user-states';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import InputAdornment from '@mui/material/InputAdornment';
import { paths } from 'src/routes/paths';
import { Chip, Stack, Typography } from '@mui/material';
import { useRouter } from 'src/routes/hook';
import { useProduct } from '../product/hooks';


// ----------------------------------------------------------------------


type Props = {
  open: boolean;
  onClose: VoidFunction;
  currentAddon?: any;
  store?: any;
  category?: any;
};

export default function AddonQuickForm({ currentAddon, open, onClose, store, category }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [products, setproducts] = useState<any[]>([]);
  const router = useRouter();
  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
  //  product: Yup.array().required('El producto es requerido'),
    price: Yup.number().required('El precio es requerido'),    
  });

  

  const defaultValues = useMemo(
    () => ({
      id: currentAddon?.id,
      name: currentAddon?.name || '',
      product: currentAddon?.product?.id ? [currentAddon?.product?.id] : [],
      category: category?.id,
      price: currentAddon?.price || 0,
      min: currentAddon?.min || '',
      max: currentAddon?.max || '',
      description: currentAddon?.description || '',
    }),
     
    [currentAddon, category]
  );
  const methods = useForm<any>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });
  useEffect(() => {

    const getProductList = () => {
      if (store?.id) {
        axios
          .get(API_ENDPOINTS.product.store(store?.id, 1))
          .then(({ data }) => {
            setproducts(data);
          })
          .catch((error) => {

          })
      }
    }
    if (store) {
      getProductList();
    }

  }, [store])

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const editAddon = useCallback(
    async (id: string, params: any) => {      
      const { name, price, min, max, product, description } = params;
      const PARAMS = {
        category: category.id,
        min: parseInt(min, 10) ||0,
        max: parseInt(max, 10) ||0,
        name,
        price,
        description,
        products: product
      }
      axios
        .put(API_ENDPOINTS.productAddon.editMultiple(id), PARAMS)
        .then(({ data }) => {

          enqueueSnackbar('Variante editada con éxito');
          onClose();
          reset();
          router.reload();
        })
        .catch((error) => {

        });
    },
    [enqueueSnackbar, reset, category, onClose, router]
  );
  useEffect(() => {
    const defaults = {
      id: currentAddon?.id,
      name: currentAddon?.name || '',
      product:  currentAddon?.products.map((product: any) => product.id) || [],
      category: category?.id,
      price: currentAddon?.price || 0,
      min: currentAddon?.min || '',
      max: currentAddon?.max || '',
      description: currentAddon?.description || '',
    }
    reset(defaults);
  }, [currentAddon, reset, category])
  const createAddon = useCallback(
    async (params: any) => {
      if (!category) {
        enqueueSnackbar('Categoría no proporcionada', { variant: 'error' });
        return;
      }
      const { name, price, min, max, product, description } = params;
      const PARAMS = {
        category: category?.id,
        min: parseInt(min, 10) ||0,
        max: parseInt(max, 10) ||0,
        name,
        price,
        products: product,
        description
      }
      axios
        .post(API_ENDPOINTS.productAddon.createMultiple, PARAMS)
        .then(({ data }) => {
          enqueueSnackbar('Variante creada con éxito');
          reset();
          onClose();
          router.reload();
        })
        .catch((error) => {         
          enqueueSnackbar('Error al crear la variante', { variant: 'error' });
        });
    },
    [enqueueSnackbar, reset, category, onClose, router]
  );

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        if (currentAddon?.id) {          
          editAddon(currentAddon?.id, data);
        } else {
          createAddon(data);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [currentAddon, editAddon, createAddon]
  );

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{currentAddon ? 'Editar variante' : 'Nueva variante'}</DialogTitle>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            p={1}
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >

            <RHFTextField name="name" label="Nombre" />
                   
            <RHFMultiSelect
                  chip                  
                  name="product"
                  label="Productos"
                  helperText="Este campo es opcional"
                  placeholder="Seleccione uno o más productos"
                  options={products.map((option: any) => ({
                    value: option.id,
                    label: option.name,
                  }))}
                />
        
            {/*
              <RHFAutocomplete
                name="product"
                label="Productos"
                disabled={!!currentAddon}
                autoHighlight
                multiple
                options={products.map((option) => option)}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )}
              />
          
          
            */}
           
              

            <RHFTextField
              name="price"
              label="Precio"
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component="span" sx={{ color: 'text.disabled' }}>
                      $
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
            <RHFTextField name="min" label="Cantidad mínima" type="number" />
            <RHFTextField name="max" label="Cantidad máxima" type="number" />
            <RHFTextField name="description" label="Descripción" type="text" />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {currentAddon ? 'Editar' : 'Agregar'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
