import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
// import { onMessageListener, requestForToken } from 'src/firebaseConfig';
import { useDispatch } from 'react-redux';
import { useAuthContext } from 'src/auth/hooks';
import {
  setNotificationConversationUpdate,
  setNotificationInstanceUpdate,
  setNotificationQR,
  setNotificationNewOrder,
} from 'src/redux/slices/notification';
import { hideSpinner } from 'src/redux/slices/spinner';
import OrderNewModal from 'src/sections/order/order-new-modal';
import { useLocales } from 'src/locales';
import { HOST_WS } from 'src/config-global';
import { Button } from '@mui/material'
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import { Mixpanel } from 'src/Mixpanel';
import { useSnackbar } from '../snackbar';
import { useSettingsContext } from '../settings/context';





const Notification = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [notification, setNotification] = useState({ title: '', body: '', order: '', storeName: '' });
  const { authenticated } = useAuthContext();
  const dispatch = useDispatch();
  const [newOrder, setNewOrder] = useState(false);
  const [order, setOrder] = useState(null);
  const { t } = useLocales();
  const closeModal = () => {
    setNewOrder(false);
  };
  const router = useRouter();
  const settings = useSettingsContext();
 /* useEffect(() => {
    if (authenticated) {
    
      requestForToken().then(async (tokenValue) => {
        try {
          await axios.post(API_ENDPOINTS.user.token, { token: tokenValue });
        } catch (error) {
          console.error(error);
        }
      });
    
    }
  }, [authenticated, dispatch]); */
  useEffect(() => {
    const notifServerURL = HOST_WS
    const notifWebSocket = new WebSocket(HOST_WS || "") ;
    // const token = sessionStorage.getItem('accessToken');
    const token = localStorage.getItem('accessToken');

    notifWebSocket.onopen = () => {
      const authElements = JSON.stringify({ userData: token });
      notifWebSocket.send(authElements);
    };


   

    notifWebSocket.onmessage = (event) => {
      try{
        const {command, body, title, data}= JSON.parse(event.data);
        if (title) {
          if (command !== 'channel_new_bot_message' && command !== 'channel_status_update'){
            setNotification({title, body, order:data.order_id, storeName:''});
          }
        }
          
          
        
        switch (command) {
          case 'qr_update': {
            dispatch(hideSpinner());
            const QR = data.qr_code;
            dispatch(setNotificationQR(QR));
          }
            break;
          case 'channel_status_update':
            Mixpanel.track("Instance Status Update",{id: data?.id, username: data?.sername, channelName: data?.name, event: data?.event_name});
            switch (data.event_name) {
              case 'connected': {
                setNotification({ title, body: `${data.name} connected`, order: '', storeName: data.store_name });
                break;
              }
              case 'disconnected': {
                setNotification({ title, body: `${data.name} disconnected`, order: '', storeName: data.store_name });
                break;
              }
              case 'deleted': {
                setNotification({ title, body: `${data.name} deleted`, order: '', storeName: data.store_name });
                break;
              }
              default: {
                setNotification({title, body, order: '',storeName:''});
                break
              }
          }
            dispatch(setNotificationInstanceUpdate(data));
            break;
          case 'channel_new_message': {            
            const SOURCE = data.message_type === 'store' ? 'store-whatsapp' : data.message_type;
            Mixpanel.track('New Message', {source: SOURCE})
            dispatch(setNotificationConversationUpdate(data));
            if (settings.notifications === 'on'){
              const audio = new Audio('/sounds/new_message_sound.mp3');
              audio.muted = true;
              audio.addEventListener("canplay", () => {
                /* the audio is now playable; play it if permissions allow */
                try {
                  audio.play();
                } catch(e) {
                  console.log(e);
                }
              });
            }
            
            // audio.play();
          }
            break;
            // cuando el bot contesta
            case 'channel_new_bot_message':
              // message_type = 'assitant' 'store' 'user'              
              Mixpanel.track('New Message', {source: data.message_type})
              dispatch(setNotificationConversationUpdate(data));
              break;
          case 'new_order':
            Mixpanel.track("New Order", {id: data.order.id})
            axios
            .get(API_ENDPOINTS.order.details(data.order.id || ''))
            .then(({ data: orderData }) => {
              setOrder(orderData);
              setNewOrder(true);
              dispatch(setNotificationNewOrder(data));
            })
            .catch((error) => {
              // Handle error here if necessary
            });
            break;
          default:
            break;
        }
      } catch (e) {
        console.log(e);
      }
      
    };

    return () => {
      notifWebSocket.close();
    };

  }, [dispatch, t, router, settings.notifications]);


  const handleNotificationClick = useCallback((orderId:string) => {
    router.push(paths.dashboard.order.details(orderId));
     
  }, []);

  useEffect(() => {
    if (notification?.title) {
      const message = t(notification?.body, {storeName: notification?.storeName});
      const actionButton = notification?.order ? (
        <Button
          variant="outlined"
          onClick={() => handleNotificationClick(notification?.order)}
          color="primary"
        >
          Ver Pedido
        </Button>
      ) : null;
  
      enqueueSnackbar(message, {
        action: (id) => actionButton,
      });
    }
  }, [notification, enqueueSnackbar, t, handleNotificationClick]);
  


  const channel = new BroadcastChannel('notifications');

  channel.onmessage = (event) => {
    if (settings.notifications === 'on'  && event.data && event.data.type === 'channel_new_message') {
      const audio = new Audio('/sounds/new_message_sound.mp3');
      audio.play();
    }
    if (event.data && event.data.type === 'new_order') {
      const data_parse = event.data.data_parse;
      axios
        .get(API_ENDPOINTS.order.details(data_parse.data.order.id || ''))
        .then(({ data: orderData }) => {
          setOrder(orderData);
          setNewOrder(true);
          dispatch(setNotificationNewOrder(data_parse));
        })
        .catch((error) => {
          // Handle error here if necessary
        });
    }
  };


  return <>{newOrder && <OrderNewModal closeModal={closeModal} order={order} />}</>;
};

export default Notification;
