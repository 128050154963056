import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// routes
// _mock
import { USER_STATUS_OPTIONS } from 'src/_mock';
// types
import { IUserItem } from 'src/types/user';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { CustomFile } from 'src/components/upload';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
  RHFMultiSelect,
} from 'src/components/hook-form';
import { IStoreType } from 'src/types/store';
import useStores from 'src/hooks/use-stores';
import useRoles from 'src/hooks/use-roles';
import useUserStates from 'src/hooks/use-user-states';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { InputAdornment, Stack } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { setNotificationQR } from 'src/redux/slices/notification';
import { useBoolean } from 'src/hooks/use-boolean';
import { id } from 'date-fns/locale';

// ----------------------------------------------------------------------

type Props = {
  storeId: string;
  onReject: any;
  onClose: VoidFunction;
  open: boolean;
};

export default function OrderRejectedModal({ onReject, open, onClose, storeId }: Props) {
  const qrModal = useBoolean();
  const [denyReasons, setDenyReasons] = useState([]);
  const DenyReasonSchema = Yup.object().shape({
    reason_id: Yup.string().required('El motivo de rechazo es requerido')
  });
  const defaultValues = useMemo(
    () => ({
      reason_id: '',
    }),
     
    []
  );
 
  const methods = useForm({
    resolver: yupResolver(DenyReasonSchema),
    mode: 'onBlur',
    defaultValues
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
    setValue
  } = methods;
  useEffect(() => {
    const getDenyReasons = () => {
         if(storeId){
          axios
          .get(API_ENDPOINTS.denyReason.store(storeId))
          .then(({ data }) => {
            setDenyReasons(data);
          })
          .catch((error) => {
            
          });
         } else {
          setDenyReasons([]);
          reset()
         }
      }
    getDenyReasons();
  }, [storeId, reset]);

  const onSubmit = useCallback(
    async (params: any) => {
        const {reason_id} = params;
        onReject(reason_id);
        onClose();
        reset();
    },
    [reset, onReject, onClose]
  );
  return (
    <Dialog
      // fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
      onClick={(event) => event.stopPropagation()}
    >
      <FormProvider methods={methods}  onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Seleccione un motivo de rechazo</DialogTitle>

      <DialogContent>
        <Stack spacing={1.5}>
          <RHFSelect
            name="reason_id"
            placeholder="Seleccione un motivo de rechazo"
            InputLabelProps={{ shrink: true }}
            defaultValue="" 
            onChange={(event) => {
              // console.log(event.target.value);
              event.stopPropagation(); // Detener la propagación del evento
              setValue('reason_id', event.target.value)
              // Resto del código de manejo del evento
            }}       
          >
            <MenuItem key="default" value="">
              Seleccione un motivo de rechazo
            </MenuItem>
            {denyReasons.map((denyReason: any) => (
              <MenuItem key={denyReason?.id} value={denyReason?.id}>
                {denyReason?.name}
              </MenuItem>
            ))}
          </RHFSelect>
        </Stack>
       
      </DialogContent>

      <DialogActions>
        <Button variant="outlined"  type="submit">
          Enviar
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
