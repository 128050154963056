import * as Yup from 'yup';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Box,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  Slide,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// _mock
import { _orders, ORDER_STATUS_OPTIONS } from 'src/_mock';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { yupResolver } from '@hookform/resolvers/yup';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
import { useLocales } from 'src/locales';
import useHasRoles from 'src/hooks/use-has-roles';
import { bgGradient } from 'src/theme/css';

//
import useStores from 'src/hooks/use-stores';
import useChannelStates from 'src/hooks/use-channel-states';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { countries } from 'src/assets/data';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { QRCodeSVG } from 'qrcode.react';
import { varFade } from 'src/components/animate';
import { useDispatch } from 'src/redux/store';
import { showSpinner } from 'src/redux/slices/spinner';
import { TransitionProps } from '@mui/material/transitions';
import { m } from 'framer-motion';
import CategoryTableRow from '../channel-table-row';
import ChannelTableToolbar from '../channel-table-toolbar';
import ChannelTableFiltersResult from '../channel-table-filters-result';
import InstanceItem from '../instance-item';
import QRInstance from '../qr-instance';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Canal', width: 280 },
  { id: 'username', label: 'Nombre de usuario', width: 280 },
  { id: 'store', label: 'Tienda', width: 280 },
  { id: 'state', label: 'BOT', width: 280 },
  { id: 'status', label: 'Conexión WhatsApp', width: 280 },
  { id: 'billingPlan', label: 'Plan de pago', width: 280 },
  { id: 'qr', width: 100 },
  { id: 'On/Off', width: 100 },
  { id: '', width: 88 },
];

const defaultFilters: {
  store: string;
  status: string;
  channelName: string;
} = {
  store: '',
  status: 'all',
  channelName: '',
};
// ----------------------------------------------------------------------

export default function InstanceNewListView({ currentStore }: any) {
  const table = useTable({ defaultOrderBy: 'id' });

  const settings = useSettingsContext();

  const { enqueueSnackbar } = useSnackbar();
  const instanceState = useSelector((state: any) => state.instanceState);

  const router = useRouter();

  const confirm = useBoolean();
  const { t } = useLocales();
  const showLoader = useBoolean();
  const theme = useTheme();

  const [tableData, setTableData] = useState<any[]>([]);

  const [basePath, setBasePath] = useState('');
  const [stores] = useStores({ light: true });
  const [filters, setFilters] = useState(defaultFilters);
  const [channels, setChannels] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [showQrModal, setShowQrModal] = useState(false);
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !!filters.store;

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;
  const isAdmin = useHasRoles(['ROLE_ADMIN']);

  const NewInstanceSchema = Yup.object().shape({    
  });

  const defaultValues = useMemo(
    () => ({
      username: '',
      default_channel: channels?.length > 0 ? channels[0].base_path : '',
    }),
     
    [channels]
  );
  const methods = useForm<any>({
    resolver: yupResolver(NewInstanceSchema),
    defaultValues,
    mode: 'onBlur',
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (params: any) => {
      setLoading(true);
      const { username } = params;      
      const aux = {
        username,
        store: parseInt(currentStore?.id, 10),        
      };
      axios
        .post(API_ENDPOINTS.whatsapp.create, aux)
        .then(({ data }) => {
          // no muestro el QR en un modal, porque lo uso en la interfaz 
          setShowQrModal(false);
         // dispatch(showSpinner());
          enqueueSnackbar('En unos instantes podrás escanear el QR que conectará tu bot');                    
          reset();
        })
        .catch((error) => {});
    },
    [enqueueSnackbar, reset, currentStore?.id]
  );
  const handleFilters = useCallback(
    (name: string, value: any) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );
  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const [states] = useChannelStates();
  const STATUS_OPTIONS = [
    { value: 'all', label: 'Todos' },
    ...states.map((state: any) => ({
      label: state.name,
      value: state.name,
      id: state.stateId,
    })),
  ];
  const handleDeleteRow = useCallback(
    (bp: string, id: string) => {
      axios
        .delete(API_ENDPOINTS.instance.delete(bp, id))
        .then((val) => {
          enqueueSnackbar('Instancia eliminada con éxito');
          const deleteRow = tableData.filter((row) => row.id !== id);
          setTableData(deleteRow);
          table.onUpdatePageDeleteRow(dataInPage.length);
        })
        .catch((err) => {});
    },
    [dataInPage.length, table, tableData, enqueueSnackbar]
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter((row) => table.selected.includes(row.id));

    deleteRows.forEach((row) => {
      handleDeleteRow(row.basePath, row.id);
    });
    /*
    setTableData(deleteRows);
    ssss
    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    }); */
  }, [table, tableData, handleDeleteRow]);

  const handleViewRow = useCallback(
    (id: string) => {
      router.push(paths.dashboard.order.details(id));
    },
    [router]
  );
  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const notification = useSelector((state: any) => state.notification);


 
  const getChannelList = useCallback(() => {
    const URL = API_ENDPOINTS.channel.storeQuery(currentStore?.id, '');
    axios
      .get(URL)
      .then(({ data }) => {               
        setTableData(data);
      })
      .catch((error) => {});
  }, [currentStore?.id]);
  useEffect(() => {
    getChannelList();
  }, [currentStore, getChannelList]);

  useEffect(() => {
    if (notification.notification) {
      const qr = notification.notification;
      setQrCode(qr);
    }
    if (notification.instanceState) {
      const { event_name, auth_status } = notification.instanceState;
      if (event_name.toLowerCase() === 'disconnected' || event_name.toLowerCase() === 'connected'){
        setLoading(false);
        setQrCode('');
      }
      getChannelList();
    }
  }, [notification, filters, getChannelList]);

  const getChannelAvailableList = useCallback(() => {
    axios
      .get(API_ENDPOINTS.channel.availables)
      .then(({ data }) => {
        setChannels(data);
      })
      .catch((error) => {});
  }, []);
  useEffect(() => {
    getChannelAvailableList();
  }, [getChannelAvailableList]);
  const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i: number) => {
      const delay = 1 + i * 0.5;
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
          opacity: { delay, duration: 0.01 }
        }
      };
    }
  };
  const storeView = (
    <>
      <Grid container spacing={2} rowGap={2}>
        <Grid item xs={12}>
          <Stack
            flexDirection={{ xs: 'column', md: 'row' }}
            sx={{
              ...bgGradient({
                                direction: '135deg',
                                startColor: alpha(theme.palette.primary.light, 0.2),
                                endColor: alpha(theme.palette.primary.main, 0.2),
                             }),
              height: { md: 1 },
              borderRadius: 2,
              position: 'relative',
              color: 'primary.darker',
              backgroundColor: 'common.white',
            }}
          >
            <Stack
              flexGrow={1}
              justifyContent="center"
              alignItems={{ xs: 'center', md: 'flex-start' }}
              sx={{
                p: {
                  xs: theme.spacing(5, 3, 0, 3),
                  md: theme.spacing(5),
                },
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              <Typography paragraph variant="h4" sx={{ whiteSpace: 'pre-line' }}>
                ¡Conectá tu Bot!
              </Typography>
              <ol style={{ color: '#637381', textAlign: 'justify', whiteSpace: 'pre-line' }}>
                <li>
                  Abre <strong>WhatsApp</strong> en tu teléfono con Android principal.
                </li>
                <li>
                  Toca el ícono <Iconify icon="eva:more-vertical-fill" color="success" /> en Android
                  o <strong>Configuración</strong> en iPhone
                </li>
                <li>
                  Toca en <strong>Dispositivos vinculados</strong> y, luego,{' '}
                  <strong>Vincular un dispositivo</strong>.
                </li>
                <li>
                  Apunta la cámara de tu teléfono hacia esta pantalla para escanear el código QR.
                </li>
              </ol>
            </Stack>
            <Stack
              flexGrow={1}
              justifyContent="center"
              alignItems={{ xs: 'center', md: 'flex-start' }}
              sx={{
                p: {
                  xs: theme.spacing(5, 3, 0, 3),
                  md: theme.spacing(5),
                },
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" spacing={1.5} m={3} textAlign="center"
              >
              <Box
                  mt={3}
                  sx={{
                   // filter: qrCode ? '' : 'blur(4px)',
                  }}
                >                
                 {qrCode ? <QRCodeSVG
                    value={qrCode}
                    includeMargin={false}
                    size={300}
                    imageSettings={{
                      src: '/assets/logonegro.svg',
                      x: undefined,
                      y: undefined,
                      height: 50,
                      width: 100,
                      excavate: true,
                    }}
                  /> : <LoadingButton
                  type="submit"
                  variant="contained"
                  size='large'
                  loading={loading}
                  endIcon={<Iconify icon="solar:qr-code-bold" />}
                >
                  Conectar Bot
                </LoadingButton>}
                </Box>
                {/* <Stack component="span" justifyContent="center" spacing={1} direction="row">                
                  <RHFTextField name="username" label="Número de teléfono"  disabled={loading}/>
                  </Stack> */}                
              </Stack>
            </FormProvider>
            </Stack>
          </Stack>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          {dataFiltered.map((row) => (
            <Grid item xs={12} sm={6} md={4} key={`${row.id}-grid`}>
              <InstanceItem
                key={row.id}
                row={row}
                onDeleteRow={() => handleDeleteRow(row.basePath, row.id)}
                onQRGeneration = {() => setShowQrModal(true)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
  const adminView = (
    <>
      <Card>
        <Tabs
          value={filters.status}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (them) => `inset 0 -2px 0 0 ${alpha(them.palette.grey[500], 0.08)}`,
          }}
        >
          {STATUS_OPTIONS.map((tab) => (
            <Tab
              key={tab.value}
              iconPosition="end"
              value={tab.value}
              label={tab.label}
              icon={
                <Label
                  variant={
                    ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                  }
                  color={
                    (tab.value === 'Active' && 'success') ||
                    (tab.value === 'Paused' && 'warning') ||
                    (tab.value === 'Dissabled' && 'error') ||
                    'default'
                  }
                >
                  {tab.value === 'all' && tableData.length}
                  {tab.value === 'Active' &&
                    tableData.filter((instance) => instance.state?.name === 'Active').length}

                  {tab.value === 'Paused' &&
                    tableData.filter((instance) => instance.state?.id === 'Change Password').length}
                  {tab.value === 'Dissabled' &&
                    tableData.filter((instance) => instance.state?.name === 'Dissabled').length}
                  {tab.value === 'Created' &&
                    tableData.filter((instance) => instance.state?.name === 'Created').length}
                </Label>
              }
            />
          ))}
        </Tabs>
        <ChannelTableToolbar filters={filters} onFilters={handleFilters} storeOptions={stores} />
        {canReset && (
          <ChannelTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            onResetFilters={handleResetFilters}
            //
            results={dataFiltered.length}
            storeOptions={stores}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
              />

              <TableBody>
                {dataFiltered
                  .slice(
                    table.page * table.rowsPerPage,
                    table.page * table.rowsPerPage + table.rowsPerPage
                  )
                  .map((row) => (
                    <CategoryTableRow
                      key={row.id}
                      row={row}
                      selected={table.selected.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.basePath, row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                    />
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={dataFiltered.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>
    </>
  );
  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {storeView}
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Eliminar selección"
        content={
          <>
            Estás seguro que deseas eliminar <strong> {table.selected.length} </strong> instancias?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Eliminar
          </Button>
        }
      />
      <QRInstance showQrModal={showQrModal}/>
   
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: any[];
  comparator: (a: any, b: any) => number;
  filters: any;
}) {
  const { store, status } = filters;
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  inputData = stabilizedThis.map((el) => el[0]);

  if (status !== 'all') {
    inputData = inputData.filter((instance) => instance.state?.name === status);
  }

  return inputData;
}
