import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { Typography } from '@mui/material';
import Stack from '@mui/system/Stack';
import FormProvider, { RHFAutocomplete } from '../hook-form';

type Props = {
  onChange: Function;
  storeId: any;
  selectedCategory?: any;
};
const CategorySearch = ({ onChange, storeId, selectedCategory }: Props) => {
  const [categories, setCategories] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<any>(null);

  const defaultValues = useMemo(
    () => ({
      category: null,
    }),
     
    []
  );
  useEffect(() => {
    const getCategoriesList = () => {
      if (storeId) {
        axios
          .get(API_ENDPOINTS.category.storeLight(storeId))
          .then(({ data }) => {
            setCategories(data);
          })
          .catch((error) => {});
      }
    };
    getCategoriesList();
  }, [storeId]);

  const methods = useForm<any>({
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    trigger,
    reset,
    getValues,
  } = methods;

  const onSubmit = useCallback(
    async (data: any) => {
      onChange(data.category);
    },
    [onChange]
  );
  const handleAutocompleteChange = useCallback(
    (selectedOptionCategory: any) => {
      // Actualiza el valor del control con la opción seleccionada
      setValue('category', selectedOptionCategory);
      // Llama a onSubmit después de que se selecciona una opción
      onSubmit(getValues());
    },
    [getValues, onSubmit, setValue]
  );

  useEffect(() => {
    if (selectedCategory === null) {
      setSelectedOption(null);
    } else {
      setSelectedOption(selectedCategory);
    }
  }, [selectedCategory]);

  return (
    <>
      {categories && (
        <>
          <Stack spacing={1.5} sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2">Categoría</Typography>
            <RHFAutocomplete
              placeholder='Selecciona una categoría de producto'
              name="category"
              //  label="Productos"
              disableClearable
              autoHighlight
              value={selectedOption}
              onChange={(event, value) => {
                handleAutocompleteChange(value);
                console.log(value);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={categories.map((option) => option)}
              getOptionLabel={(option: any) => option?.name || ''}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
            />
          </Stack>
        </>
      )}
    </>
  );
};
export default CategorySearch;
