import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { Typography } from '@mui/material';
import Stack from '@mui/system/Stack';
import FormProvider, { RHFAutocomplete } from '../hook-form';


type Props = {
  onChange: Function;
  storeId: any;
  categoryId?: any;
  title?: string;
  selectedProducts?: any[];
};
const ProductSearchMultiple = ({ onChange, storeId,categoryId, title ='Productos', selectedProducts }: Props) => {
  const [products, setProducts] = useState<any[]>([]);
  const defaultValues = useMemo(
    () => ({
      products: [],
    }),
     
    []
  );
  useEffect(() => {
    const getProductList = () => {
      if (storeId) {
        axios
          .get(API_ENDPOINTS.product.storeQuery(storeId, categoryId ? `?light=1&category=${categoryId}`: `?light=1`))
          .then(({ data }) => {
            setProducts(data);
          })
          .catch((error) => {

          })
      }
    }
    getProductList();
  }, [storeId, categoryId])

  const methods = useForm<any>({
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    trigger,
    getValues,
  } = methods;

  const onSubmit = useCallback(
    async (data: any) => {
      onChange(data.products);
    },
    [onChange]
  );
  const handleAutocompleteChange = useCallback(
    (selectedOption: any) => {
      // Actualiza el valor del control con la opción seleccionada
      setValue('products', selectedOption);
      // Llama a onSubmit después de que se selecciona una opción
      onSubmit(getValues());
    },
    [getValues, onSubmit, setValue]
  );
  useEffect(() => {
    setValue('products', selectedProducts);   
   }, [selectedProducts, setValue, onSubmit, getValues])
   
  return (
    <>
      {products && (
        <>
            <Stack spacing={1.5}>
            <Typography variant="subtitle2">{title}</Typography>
            <RHFAutocomplete
          name="products"          
        //  label="Productos"
          placeholder='Selecciona uno o más productos'
          disableClearable
          autoHighlight
          multiple
          limitTags={5}
          onChange={(event, value) => {
            handleAutocompleteChange(value);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={products.map((option) => option)}
          getOptionLabel={(option: any) => option?.name || ''}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
        />
        
        </Stack>
        </>
      )}
    </>
  );
};
export default ProductSearchMultiple;
