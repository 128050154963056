import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/system/Stack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { useResponsive } from 'src/hooks/use-responsive';
import { RootState } from 'src/redux/store';
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import { IProductAddonCategoryForm } from 'src/types/product';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import * as Yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import { IStoreItem } from 'src/types/store';
import useStores from 'src/hooks/use-stores';
import useHasRoles from 'src/hooks/use-has-roles';

type Props = {
  currentCategory?: IProductAddonCategoryForm;
};
type FormValuesProps = any;

export default function ProductAddonsNewEditForm({ currentCategory }: Props) {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');
  const stateStore: any = useSelector((state: RootState) => state.store);  
  const isAdmin = useHasRoles(['ROLE_ADMIN']);
  const [selectedStore, setSelectedStore] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const NewPromptSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
  });
  const defaultValues = useMemo(
    () => ({
      name: currentCategory?.name || '',
      store: stateStore.id || '',
      order: currentCategory?.itemOrder || '',
      min: currentCategory?.min || '',
      max: currentCategory?.max || '',
    }),
     
    [currentCategory]
  );
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewPromptSchema),
    defaultValues,
    mode: 'onBlur',
  });
  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  useEffect(() => {
    if (currentCategory) {
      reset(defaultValues);
    }
  }, [currentCategory, defaultValues, reset]);

  const editPrompt = useCallback(
    async (id: string, params: FormValuesProps) => {
      const { name, store, order, min, max } = params;
      const PARAMS = {
        name,
        store: parseInt(stateStore.id, 10),
        order: parseInt(order, 10) || 0,
        min: parseInt(min, 10) || 0,
        max: parseInt(max, 10) || 0,
      };
      axios
        .put(API_ENDPOINTS.productAddonCategory.edit(id), PARAMS)
        .then(({ data }) => {
          enqueueSnackbar('Categoría de variante editada con éxito');
          reset();
          router.push(paths.dashboard.productAddonCategory.root);
        })
        .catch((error) => {});
    },
    [enqueueSnackbar, reset, router, stateStore.id]
  );
  const createPrompt = useCallback(
    async (params: FormValuesProps) => {
      const { name, order, min, max, store } = params;
      console.log();
      const PARAMS = {
        name,
        store: parseInt(stateStore.id, 10),
        order: parseInt(order, 10) || 0,
        min: parseInt(min, 10) || 0,
        max: parseInt(max, 10) || 0,
      };
      axios
        .post(API_ENDPOINTS.productAddonCategory.create, PARAMS)
        .then(({ data }) => {
          enqueueSnackbar('Categoría de variante creada con éxito');
          reset();
          router.push(paths.dashboard.productAddonCategory.root);
        })
        .catch((error) => {});
    },
    [enqueueSnackbar, reset, router, stateStore.id]
  );

  const onSubmit = useCallback(
    async (params: FormValuesProps) => {
      if (currentCategory) {
        editPrompt(currentCategory.id, params);
      } else {
        createPrompt(params);
      }
    },
    [currentCategory, editPrompt, createPrompt]
  );

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Categoría de variante
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Complete los siguientes datos...
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Detalle de la categoría de variantes" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Nombre</Typography>
              <RHFTextField name="name" placeholder="Ingrese el nombre de la categoría" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Cantidad mínima</Typography>
              <RHFTextField
                name="min"
                placeholder="Ingrese la cantidad mínima de la categoría"
                type="phone"
              />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Cantidad máxima</Typography>
              <RHFTextField
                name="max"
                placeholder="Ingrese la cantidad máxima de la categoría"
                type="phone"
              />
            </Stack>
            {isAdmin && 
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Orden de aparición en el Menú</Typography>
              <RHFTextField
                name="order"
                placeholder="Ingrese el orden"
                type="number"
                helperText="Es el orden en el que esta variante aparecerá en el menú"
              />
            </Stack>
            }
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentCategory ? 'Crear categoría' : 'Guardar cambios'}
        </LoadingButton>
      </Grid>
    </>
  );
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
