// @mui
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
// hooks
// _mock
// components
import { useSettingsContext } from 'src/components/settings';
// assets
import { MotivationIllustration } from 'src/assets/illustrations';
//
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'src/components/snackbar';
import useHasRoles from 'src/hooks/use-has-roles';
import { useLocales } from 'src/locales';
import { RootState, useSelector } from 'src/redux/store';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { fDateTime } from 'src/utils/format-time';
import MetricsFilters from '../metrics-filters';
import MetricsPieChart from '../metrics-pie-chart';
import TotalizerWidget from '../metrics-totalizer';
import { Card, CircularProgress, Divider, Typography } from '@mui/material';
import Spinner from 'src/components/spinner/spinner';

// ----------------------------------------------------------------------

const defaultFilters: {
  startDate: string;
  endDate: string;
  billingPlan?: string;
  stores?: string[];
} = {
  startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'), // 7 días atrás incluyendo hoy
  endDate: moment().format('YYYY-MM-DD'), // hoy
  billingPlan: '',
  stores: [],
};


export default function MetricsView() {
  const { t } = useLocales();
  
  const stateStore: any = useSelector((state: RootState) => state.store);
  // tiendas elegidas para las metricas
  const [filters, setFilters] = useState(defaultFilters);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingMetrics, setLoadingMetrics] = useState({
    averageConversationDuration: false,
    countBotMessages: false,
    countConversations: false,
    countConversationsBot: false,
    countConversationsFullAI: false,
    rateFullAI: false,
    countCustomerMessages: false,
    countCustomers: false,
    countMerchantInvolved: false,
    countMerchantMessages: false,
    countMerchantMessagesFromApp: false,
  });

  const metricsData = {
    averageConversationDuration: 0,
    countBotMessages: 0,
    countConversations: 0,
    countConversationsBot: 0,
    countConversationsFullAI: 0,
    rateFullAI: 0,
    countCustomerMessages: 0,
    countCustomers: 0,
    countMerchantInvolved: 0,
    countMerchantMessages: 0,
    countMerchantMessagesFromApp: 0,
  };

  const [metrics, setMetrics] = useState<any>(metricsData);  

  const getAverageConversationDuration = () => {
    setLoadingMetrics((prevLoadingMetrics: any) => ({
      ...prevLoadingMetrics,
      averageConversationDuration: true
    }));
    const PARAMS = createParams(filters);
    if (!PARAMS) return;
    const URL = API_ENDPOINTS.dashboard.averageConversationDuration();
    axios.post(URL, PARAMS).then(({ data }) => {
      let average_conversation_duration_formatted = '';
      if (data) {
        const totalSeconds = data * 60; // Convertir minutos a segundos
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const secs = Math.floor(totalSeconds % 60);
        average_conversation_duration_formatted = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
      }
      setMetrics((prevMetrics: any) => ({
        ...prevMetrics,
        averageConversationDuration: average_conversation_duration_formatted
      }));
      setLoadingMetrics((prevLoadingMetrics: any) => ({
        ...prevLoadingMetrics,
        averageConversationDuration: false
      }));
    });
  };

  const getCountConversations = () => {
    setLoadingMetrics((prevLoadingMetrics: any) => ({
      ...prevLoadingMetrics,
      countConversations: true
    }));
    const PARAMS = createParams(filters);
    if (!PARAMS) return;
    const URL = API_ENDPOINTS.dashboard.countConversations();
    axios.post(URL, PARAMS).then(({ data }) => {
      setMetrics((prevMetrics: any) => ({
        ...prevMetrics,
        countConversations: data
      }));
      setLoadingMetrics((prevLoadingMetrics: any) => ({
        ...prevLoadingMetrics,
        countConversations: false
      }));
    });
  };

  const getCountConversationsBot = () => {
    setLoadingMetrics((prevLoadingMetrics: any) => ({
      ...prevLoadingMetrics,
      countConversationsBot: true
    }));
    const PARAMS = createParams(filters);
    if (!PARAMS) return;
    const URL = API_ENDPOINTS.dashboard.countConversationsBot();
    axios.post(URL, PARAMS).then(({ data }) => {
      setMetrics((prevMetrics: any) => ({
        ...prevMetrics,
        countConversationsBot: data
      }));
      setLoadingMetrics((prevLoadingMetrics: any) => ({
        ...prevLoadingMetrics,
        countConversationsBot: false
      }));
    });
  };

  const getCountConversationsFullAI = () => {
    setLoadingMetrics((prevLoadingMetrics: any) => ({
      ...prevLoadingMetrics,
      countConversationsFullAI: true
    }));
    const PARAMS = createParams(filters);
    if (!PARAMS) return;
    const URL = API_ENDPOINTS.dashboard.countConversationsFullAI();
    axios.post(URL, PARAMS).then(({ data }) => {
      setMetrics((prevMetrics: any) => ({
        ...prevMetrics,
        countConversationsFullAI: data
      }));
      setLoadingMetrics((prevLoadingMetrics: any) => ({
        ...prevLoadingMetrics,
        countConversationsFullAI: false
      }));
    });
  };

  const getRateFullAI = () => {
    setLoadingMetrics((prevLoadingMetrics: any) => ({
      ...prevLoadingMetrics,
      rateFullAI: true
    }));
    const PARAMS = createParams(filters);
    if (!PARAMS) return;
    const URL = API_ENDPOINTS.dashboard.rateFullAI();
    axios.post(URL, PARAMS).then(({ data }) => {
      setMetrics((prevMetrics: any) => ({
        ...prevMetrics,
        rateFullAI: `${(data * 100).toFixed(2)}%`
      }));
      setLoadingMetrics((prevLoadingMetrics: any) => ({
        ...prevLoadingMetrics,
        rateFullAI: false
      }));
    });
  };

  const getCountCustomerMessages = () => {
    setLoadingMetrics((prevLoadingMetrics: any) => ({
      ...prevLoadingMetrics,
      countCustomerMessages: true
    }));
    const PARAMS = createParams(filters);
    if (!PARAMS) return;
    const URL = API_ENDPOINTS.dashboard.countCustomerMessages();
    axios.post(URL, PARAMS).then(({ data }) => {
      setMetrics((prevMetrics: any) => ({
        ...prevMetrics,
        countCustomerMessages: data
      }));
      setLoadingMetrics((prevLoadingMetrics: any) => ({
        ...prevLoadingMetrics,
        countCustomerMessages: false
      }));
    });
  };

  const getCountCustomers = () => {
    setLoadingMetrics((prevLoadingMetrics: any) => ({
      ...prevLoadingMetrics,
      countCustomers: true
    }));
    const PARAMS = createParams(filters);
    if (!PARAMS) return;
    const URL = API_ENDPOINTS.dashboard.countCustomers();
    axios.post(URL, PARAMS).then(({ data }) => {
      setMetrics((prevMetrics: any) => ({
        ...prevMetrics,
        countCustomers: data
      }));
      setLoadingMetrics((prevLoadingMetrics: any) => ({
        ...prevLoadingMetrics,
        countCustomers: false
      }));
    });
  };

  const getCountMerchantInvolved = () => {
    setLoadingMetrics((prevLoadingMetrics: any) => ({
      ...prevLoadingMetrics,
      countMerchantInvolved: true
    }));
    const PARAMS = createParams(filters);
    if (!PARAMS) return;
    const URL = API_ENDPOINTS.dashboard.countMerchantInvolved();
    axios.post(URL, PARAMS).then(({ data }) => {
      setMetrics((prevMetrics: any) => ({
        ...prevMetrics,
        countMerchantInvolved: data
      }));
      setLoadingMetrics((prevLoadingMetrics: any) => ({
        ...prevLoadingMetrics,
        countMerchantInvolved: false
      }));
    });
  };

  const getCountMerchantMessages = () => {
    setLoadingMetrics((prevLoadingMetrics: any) => ({
      ...prevLoadingMetrics,
      countMerchantMessages: true
    }));
    const PARAMS = createParams(filters);
    if (!PARAMS) return;
    const URL = API_ENDPOINTS.dashboard.countMerchantMessages();
    axios.post(URL, PARAMS).then(({ data }) => {
      setMetrics((prevMetrics: any) => ({
        ...prevMetrics,
        countMerchantMessages: data
      }));
      setLoadingMetrics((prevLoadingMetrics: any) => ({
        ...prevLoadingMetrics,
        countMerchantMessages: false
      }));
    });
  };

  const getCountMerchantMessagesFromApp = () => {
    setLoadingMetrics((prevLoadingMetrics: any) => ({
      ...prevLoadingMetrics,
      countMerchantMessagesFromApp: true
    }));
    const PARAMS = createParams(filters);
    if (!PARAMS) return;
    const URL = API_ENDPOINTS.dashboard.countMerchantMessagesFromApp();
    axios.post(URL, PARAMS).then(({ data }) => {
      setMetrics((prevMetrics: any) => ({
        ...prevMetrics,
        countMerchantMessagesFromApp: data
      }));
      setLoadingMetrics((prevLoadingMetrics: any) => ({
        ...prevLoadingMetrics,
        countMerchantMessagesFromApp: false
      }));
      });
  };

  const getCountBotMessages = () => {
    setLoadingMetrics((prevLoadingMetrics: any) => ({
      ...prevLoadingMetrics,
      countBotMessages: true
    }));
      const PARAMS = createParams(filters);
    if (!PARAMS) return;
    const URL = API_ENDPOINTS.dashboard.countBotMessages();
    axios.post(URL, PARAMS).then(({ data }) => {
      setMetrics((prevMetrics: any) => ({
        ...prevMetrics,
        countBotMessages: data
      }));
      setLoadingMetrics((prevLoadingMetrics: any) => ({
        ...prevLoadingMetrics,
        countBotMessages: false
      }));
    });
  };

  useEffect(() => {
    if (filters.startDate != "" && filters.endDate != "" && (filters.stores && filters.stores?.length > 0)) {
      getAverageConversationDuration();
      getCountConversations();
      getCountConversationsBot();
      getCountConversationsFullAI();
      getRateFullAI();
      getCountCustomerMessages();
      getCountCustomers();
      getCountMerchantInvolved();
      getCountMerchantMessages();
      getCountMerchantMessagesFromApp();
      getCountBotMessages();
    }
  }, [filters]);

  const renderLoading = () => {
    return (
      <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Grid>
      </Card>
    );
  };
  



  const settings = useSettingsContext();

  const canReset = !isEqual(defaultFilters, filters);
  const handleFilters = useCallback((name: string, value: string) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  // useEffect(() => {
  //   if (filters.startDate != "" && filters.endDate != "" && (filters.stores && filters.stores?.length > 0)) {
  //     getConversations();
  //   }
  // }, [filters]);

  const createParams = (filters: any) => {
    const { startDate, endDate, stores } = filters;
    const diffInDays = moment(endDate).diff(moment(startDate), 'days');
    if (diffInDays > 365) {
      enqueueSnackbar('La diferencia entre fechas no puede ser mayor a 365 días', { variant: 'warning' });
      return null;
    }
    if (startDate && endDate) {
      if (new Date(startDate) > new Date(endDate)) {
        enqueueSnackbar('La fecha fin debe ser mayor que la fecha inicio', { variant: 'warning' });
        return null;
      }
    }
    return {
      since: filters.startDate ? filters.startDate : '',
      to: filters.endDate ? filters.endDate : '',
      stores: stores
    };
  };
 
  // const getConversations = useCallback(() => {
  //   const URL = API_ENDPOINTS.dashboard.count;
    
  //   const PARAMS = createParams(filters);
  //   if (!PARAMS) return;
  //   axios
  //     .post(URL, PARAMS)
  //     .then(({ data }) => {
  //       const { conversations, customers, messages, average_conversation_duration, merchant_involved, merchant_messages, conversations_with_bot_message, conversations_full_ai, conversations_full_ai_rate } = data;        
  //       let average_conversation_duration_formatted = '';
  //       if (average_conversation_duration) {
  //         const totalSeconds = average_conversation_duration * 60; // Convertir minutos a segundos
  //         const hours = Math.floor(totalSeconds / 3600);
  //         const minutes = Math.floor((totalSeconds % 3600) / 60);
  //         const secs = Math.floor(totalSeconds % 60);
  //         average_conversation_duration_formatted = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  //      }
  //       setMetrics((prevMetrics: any) => ({
  //         ...prevMetrics,
  //         conversations: conversations,
  //         customers: customers,
  //         messages: messages,
  //         average_conversation_duration: average_conversation_duration_formatted,
  //         merchant_involved: merchant_involved,
  //         merchant_messages: merchant_messages,
  //         conversations_with_bot_message: conversations_with_bot_message,
  //         conversations_full_ia: conversations_full_ai,
  //         full_ia_rate: `${(conversations_full_ai_rate * 100).toFixed(2)}%`
  //       }));
  //     })
  //     .catch((error) => { });
  // }, [enqueueSnackbar, filters]);
  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Grid container spacing={3}>
        
        <Grid xs={12}>
          <MetricsFilters
            title="¡Revisá las métricas de los merchants!"
            description="Elige un rango de fechas que quieras. ¡Por defecto verás los datos de la última semana!"
            filters={filters}
            onFilters={handleFilters}
            onResetFilters={() => {
              setFilters(defaultFilters);
            }}
            canReset={canReset}
            img={<MotivationIllustration />}
            action={
              <></>
            }
          />
        </Grid>
        <Grid container spacing={2} xs={12}>    
        <Grid xs={12}>
          <Typography variant="h6" gutterBottom>
            Conversaciones
          </Typography>
        </Grid>
        <Grid xs={6}>
          {loadingMetrics.countCustomers ? (
            renderLoading()
          ) : (
          <TotalizerWidget
            title="Cantidad de clientes únicos"
            percent={0}
            total={metrics?.countCustomers || 0}
            chart={{
              options: { title: { text: 'Última semana', align: 'center' } },
            }}
          />
          )}
        </Grid>
        
        <Grid xs={6} >
          {loadingMetrics.countConversations ? (
            renderLoading()
          ) : (
          <TotalizerWidget
            title="Cantidad de conversaciones"
            percent={0}
            total={metrics?.countConversations || 0}
              chart={{
                options: { title: { text: 'Última semana', align: 'center' } },
              }}
            />
          )}
        </Grid>
        <Grid xs={6} >
          {loadingMetrics.countConversationsBot ? (
            renderLoading()
          ) : (
          <TotalizerWidget
            title="Cantidad de conversaciones con IA"
            percent={0}
            total={metrics?.countConversationsBot || 0}
            chart={{
              options: { title: { text: 'Última semana', align: 'center' } },
            }}
            />
          )}
        </Grid>
        <Grid xs={6}>
          {loadingMetrics.countConversationsFullAI ? (
            renderLoading()
          ) : (
          <TotalizerWidget
            title="Cantidad de conversaciones 100% IA"
            percent={0}
            total={metrics?.countConversationsFullAI || 0}
            chart={{
              options: { title: { text: 'Última semana', align: 'center' } },
            }}
          />
          )}
        </Grid>
        <Grid xs={6}>
          {loadingMetrics.rateFullAI ? (
            renderLoading()
          ) : (
          <TotalizerWidget
            title="Porcentaje de Conversaciones 100% IA"
            percent={0}
            total={metrics?.rateFullAI || 0}
              chart={{
                options: { title: { text: 'Última semana', align: 'center' } },
              }}
            />
          )}
        </Grid>
        <Grid xs={6}>
          {loadingMetrics.averageConversationDuration ? (
            renderLoading()
          ) : (
          <TotalizerWidget
            title="Duración promedio de las conversaciones"
            percent={0}
            total={metrics?.averageConversationDuration || 0}
            chart={{
              options: { title: { text: 'Última semana', align: 'center' } },
            }}
          />
          )}
        </Grid>
      
        <Grid xs={12} md={6}>
            <MetricsPieChart
              title="Conversaciones"
              chart={{                
                series: (metrics?.countConversations) ? [
                  { label: 'Conversaciones con IA', value: metrics?.countConversationsBot },
                  { label: 'Conversaciones sin IA', value: (metrics?.countConversations - metrics?.countConversationsBot) },
                ] : [],
                colors: ['#9370DB', '#90EE90']
              }}              
            />               
        </Grid>   
        <Grid xs={12} md={6}>
            <MetricsPieChart
              title="Conversaciones intervenidas por el merchant"
              chart={{
                series: (metrics?.countMerchantInvolved) ? [
                  { label: 'Conversaciones intervenidas', value: metrics?.countMerchantInvolved },
                  { label: 'Conversaciones no intervenidas', value: (metrics?.countConversations - metrics?.countMerchantInvolved) },
                ] : [],
                colors: ['#F24B25', '#2ED3BB']
              }}
            />               
        </Grid>    
        </Grid>
        <Grid container spacing={2} xs={12} >    
        <Grid xs={12}>
          <Typography variant="h6" gutterBottom>
            Mensajes
          </Typography>
        </Grid>
        <Grid xs={6}>
          <TotalizerWidget
            title="Mensajes enviados por el asistente"
            percent={0}
            total={metrics?.countBotMessages || 0}
            chart={{
              options: { title: { text: 'Última semana', align: 'center' } },
            }}
          />
        </Grid>
        <Grid xs={6}>
          <TotalizerWidget
            title="Mensajes enviados por el merchant"
            percent={0}
            total={metrics?.countMerchantMessages || 0}
            chart={{
              options: { title: { text: 'Última semana', align: 'center' } },
            }}
          />
        </Grid>
        <Grid xs={6}>
          <TotalizerWidget
            title="Mensajes enviados por el cliente"
            percent={0}
            total={metrics?.countCustomerMessages || 0}
            chart={{
              options: { title: { text: 'Última semana', align: 'center' } },
            }}
          />
        </Grid>
        </Grid>
    
        <Grid xs={6} >
            <MetricsPieChart
              title="Mensajes de IA"
              chart={{
                series: (metrics?.countBotMessages) ? [
                  { label: 'Mensajes de IA', value: metrics?.countBotMessages },
                  { label: 'Mensajes Merchant', value: (metrics?.countMerchantMessages) },
                ] : [],
                colors: ['#622E9A', '#1BA09A']
              }}
            />               
        </Grid>        
             
        <Grid xs={6}>
            <MetricsPieChart
              title="Fuente de intervención del merchant"
              chart={{
                series: (metrics?.countMerchantMessagesFromApp) ? [
                  { label: 'Whatsapp', value: parseInt(metrics?.countMerchantMessagesFromApp?.application, 10) },
                  { label: 'Panel', value: parseInt(metrics?.countMerchantMessagesFromApp?.panel, 10) },
                ] : [],
                colors: ['#33BDAF', '#357CD2']
              }}
            />               
        </Grid>        
      </Grid>
    </Container>
  );
}
