import axios from 'axios';
import { countries } from 'src/assets/data';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
interface APIEndpointsVariant {
  [key: string]: any;
}
interface APIEndpointsStore {
  [key: string]: any;
}
interface APIEndpointsProduct {
  [key: string]: any;
}
export const API_ENDPOINTS = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/login_check',
    register: '/api/auth/register'
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/products?length=100&page=2',
    details: (id: string) => `/api/products/${id}`,
    search: '/api/product/search',
    create: '/api/products',
    createBasic: '/api/products/basic',
    edit: (id: string) => `/api/products/${id}`,
    delete: (id: string) => `/api/products/${id}`,
    store: (id: string, light: number = 0) => `/api/products/${id}/store?light=${light}`,
    storeQuery: (id: string, storeQuery: string) => `/api/products/${id}/store${storeQuery}`,
    activate: (id: string) => `/api/products/${id}/activate`,
    deactivate: (id: string) => `/api/products/${id}/deactivate`,
    query: (prodyctQuery: string) => `/api/products${prodyctQuery}`,
    addonMultiple: (id: string) => `/api/products/${id}/multiple/addons`,
    descriptionIa: (id: string) => `/api/products/${id}/description/ia`,
    name: (id: string) => `/api/products/${id}/name`,
    description: (id: string) => `/api/products/${id}/description`,
    bot_description: (id: string) => `/api/products/${id}/bot/description`,
    price: (id: string) => `/api/products/${id}/price`,
    active: (id: string) => `/api/products/${id}/toggle/active`,
    order: (id: string) => `/api/products/${id}/item/order`,
    variants: (id: string) => `/api/products/${id}/variants`,
    category: (id: string) => `/api/products/${id}/category`,
    deleteVariants: (id: string) => `/api/products/${id}/all/variants`,
    addonCategory: (productId: string, addonCategoryId: string ) => `/api/products/${productId}/product/addon/category/${addonCategoryId}`,
  } as APIEndpointsProduct,
  order: {
    list: (start: string = '0') => `/api/orders?page=${start}&length=10`,
    details: (id: string) => `/api/orders/${id}`,
    deleteProduct: (id: string) => `/api/order_vendors/${id}/product/delete`,
    updateStatus: (id: string) => `/api/order_vendors/${id}/update/status`,
    updateAddress: (id: string) => `/api/order_vendors/${id}/change/address`,
    accepted:  (id: string, preparation: string) => `/api/orders/${id}/status/accepted${preparation ? `/${preparation}` : ''}`,
    done:  (id: string) => `/api/orders/${id}/status/done`,
    finalized:  (id: string) => `/api/orders/${id}/status/finalized`,
    readyToPickup:  (id: string) => `/api/orders/${id}/status/ready/to/pickup`,
    deny:  (id: string, deny: string) => `/api/orders/${id}/status/rejected/${deny}/reason`,
    sent:  (id: string) => `/api/orders/${id}/status/sent`,
    pdf:  (id: string) => `/api/orders/${id}/pdf`,
    sendMessage:  (id: string) => `/api/orders/${id}/send/message`,
    states: '/api/orders/list/state/availables',
    totalByState: '/api/orders/totals/by/state',
    query: (listQuery: string) => `/api/orders${listQuery}`,
    store: (id: string, listQuery: string) => `/api/orders/${id}/store${listQuery}`,
    stores: (listQuery: string) => `/api/orders/from/stores${listQuery}`,
    totalByStateAndStore: (id: string) => `/api/orders/totals/by/state/${id}/store`,
  },
  prompt: {
    list: '/api/prompts',
    create: '/api/prompts',
    details: (id: string) => `/api/prompts/${id}`,
    edit: (id: string) => `/api/prompts/${id}`,
    delete: (id: string) => `/api/prompts/${id}`,
  },
  currency: {
    list: '/api/currencies',
    create: '/api/currencies',
    details: (id: string) => `/api/currencies/${id}`,
    edit: (id: string) => `/api/currencies/${id}`,
    delete: (id: string) => `/api/currencies/${id}`,
  },
  country: {
    list: '/api/countries',
    create: '/api/countries',
    details: (id: string) => `/api/countries/${id}`,
    edit: (id: string) => `/api/countries/${id}`,
    delete: (id: string) => `/api/countries/${id}`,
  },
  province: {
    list: '/api/provinces',
    create: '/api/provinces',
    details: (id: string) => `/api/provinces/${id}`,
    edit: (id: string) => `/api/provinces/${id}`,
    delete: (id: string) => `/api/provinces/${id}`,
  },
  city: {
    list: '/api/cities',
    listPagination: (start: string = '1') => `/api/cities?page=${start}&length=30&order=name&dir=ASC`,
    light: '/api/cities?light=1',
    create: '/api/cities',
    details: (id: string) => `/api/cities/${id}`,
    edit: (id: string) => `/api/cities/${id}`,
    delete: (id: string) => `/api/cities/${id}`,
  },
  store: {
    list : '/api/stores?length=100',
    light: '/api/stores?light=1',
    create: '/api/stores/food/store',
    details: (id: string) => `/api/stores/${id}`,
    edit: (id: string) => `/api/stores/${id}`,
    delete: (id: string) => `/api/stores/${id}`,
    downloadExample: (storeId: string) => `/api/stores/${storeId}/import/xlsx/example`,
    export: (storeId: string) => `/api/stores/${storeId}/export/xlsx`,
    import: (storeId: string) => `/api/stores/${storeId}/import/xlsx`,
    importVariant: (storeId: string) => `/api/stores/${storeId}/import/addons/xlsx`,
    importExample: (storeId: string) => `/api/stores/${storeId}/import/addons/xlsx/example`,
    category: '/api/store_categories',
    pdf: (storeId: string,categoryOrder: string, productOrder: string  ) => `/api/stores/${storeId}/export/products/pdf/category/${categoryOrder}/product/${productOrder}`,
    pdfBase64: (storeId: string,categoryOrder: string, productOrder: string  ) => `/api/stores/${storeId}/export/products/pdf/base64/category/${categoryOrder}/product/${productOrder}`,
    dissable: (id: string) => `/api/stores/${id}/dissable`,
    enable: (id: string) => `/api/stores/${id}/active`,
    storefaqcreate: '/api/store_faqs',
    storefaq: (id: string) => '/api/store_faqs',
    faqsStore: (id: string) => `/api/store_faqs/${id}/store`,
    storefaq_detail: (id: string) => `/api/store_faqs/${id}`,
    storefaq_edit: (id: string) => `/api/store_faqs/${id}`,
    storefaq_delete: (id: string) => `/api/store_faqs/${id}`,
    open: (id: string) => `/api/stores/${id}/open`,
    close: (id: string) => `/api/stores/${id}/close`,
    workFlowStateStore: (id: string) => `/api/stores/${id}/workflow/states`,
    workFlowStateStores: `/api/stores/workflow/states/from/stores`,
    setDelivery: (id: string) => `/api/stores/${id}/delivery/paused`,
    setClosed: (id: string) => `/api/stores/${id}/closed/paused`,
    setPreparationTime: (id: string, waitingTime: string) => `/api/stores/${id}/waiting/time/${waitingTime}`,
    listQuery: (query: string) => `/api/stores${query}`,
    method: (id: string) => `/api/stores/${id}/new/method`,
    logo: (id: string) => `/api/stores/${id}/logo`,
    name: (id: string) => `/api/stores/${id}/name`,
    store_categories: (id: string) => `/api/stores/${id}/categories`,
    city_id: (id: string) => `/api/stores/${id}/city`,
    street: (id: string) => `/api/stores/${id}/street`,
    house_number: (id: string) => `/api/stores/${id}/house/number`,
    schedule_text: (id: string) => `/api/stores/${id}/schedule/text`,
    order_state_workflow: (id: string) => `/api/stores/${id}/order/state/workflow`,
    language: (id: string) => `/api/stores/${id}/language`,
    waiting_time: (id: string) => `/api/stores/${id}/waiting/time/only`,
    notifications: (id: string) => `/api/stores/${id}/notifications`,
    send_pdf: (id: string) => `/api/stores/${id}/send/pdf`,
    close_orders_automatically: (id: string) => `/api/stores/${id}/close/orders/automatically`,
    currency_id: (id: string) => `/api/stores/${id}/currency`,
    state_accepted: (id: string) => `/api/stores/${id}/state/accepted`,
    state_done: (id: string) => `/api/stores/${id}/state/done`,
    state_ready_to_pickup: (id: string) => `/api/stores/${id}/state/ready/to/pickup`,
    state_finalized: (id: string) => `/api/stores/${id}/state/finalized`,
    state_sent: (id: string) => `/api/stores/${id}/state/sent`,
    resume_observation: (id: string) => `/api/stores/${id}/resume/observation`,
    welcome_message: (id: string) => `/api/stores/${id}/welcome/message`,
    closed_message: (id: string) => `/api/stores/${id}/closed/message`,
    closed_paused_message: (id: string) => `/api/stores/${id}/closed/paused/message`,
    alternative_currency: (id: string) => `/api/stores/${id}/alternative/currency`,
    exchange_rate: (id: string) => `/api/stores/${id}/exchange/rate`,
    ask_min_amount_delivery: (id: string) => `/api/stores/${id}/ask/min/amount/delivery`,
    min_amount_delivery: (id: string) => `/api/stores/${id}/min/amount/delivery`,
    createBasic: '/api/stores/food/store/basic',
    deliveryFixed:  (id: string) => `/api/delivery_methods/${id}/fixed`,
    deliveryUnespecified:  (id: string) => `/api/delivery_methods/${id}/unespecified`,
    deliveryVariable:  (id: string) => `/api/delivery_methods/${id}/variable`,
    deliveryMethod:  (id: string) => `/api/stores/${id}/delivery/method`,
    takeaway:  (id: string) => `/api/stores/${id}/takeaway`,    
    delivery:  (id: string) => `/api/stores/${id}/delivery`,    
    whatsapp: (id: string, channelId: string) => `/api/stores/${id}/default/whatsapp/${channelId}`,
    countryList: '/api/stores/country/list',
    planList: '/api/stores/plan/names'
  } as APIEndpointsStore,
  payments: {
    list: '/api/payment_methods'
  },
  category: {
    list: '/api/categories?length=50',
    create: '/api/categories',
    details: (id: string) => `/api/categories/${id}`,
    edit: (id: string) => `/api/categories/${id}`,
    editMultiple: (id: string) => `/api/categories/${id}/multiple/addons`,
    createMultiple: '/api/categories/multiple/addons',
    delete: (id: string) => `/api/categories/${id}`,
    store: (id: string) => `/api/categories/${id}/store?length=50`,
    storeQuery: (id: string) => `/api/categories/${id}/store?length=50&order=created_at&dir=ASC`,
    storeLight: (id: string) => `/api/categories/${id}/store?light=1`,
    activate: (id: string) => `/api/categories/${id}/activate`,
    deactivate: (id: string) => `/api/categories/${id}/deactivate`,
    addonCategory: (id: string, addonCategoryId: string) => `/api/categories/${id}/product/addon/category/${addonCategoryId}`
   },
  denyReason: {
    list: '/api/deny_reasons',
    create: '/api/deny_reasons',
    details: (id: string) => `/api/deny_reasons/${id}`,
    edit: (id: string) => `/api/deny_reasons/${id}`,
    delete: (id: string) => `/api/deny_reasons/${id}`,
    store: (id: string) => `/api/deny_reasons/${id}/store`,
  },
  role: {
    list: '/api/roles'
  },
  user: {
    list: (userQuery?: string) => `/api/users${userQuery}`,
    create: '/api/users',
    details: (id: string) => `/api/users/${id}`,
    edit: (id: string) => `/api/users/${id}`,
    delete: (id: string) => `/api/users/${id}`,
    states: `/api/users/possible/states`,
    active: (id: string) => `/api/users/${id}/active`,
    dissable: (id: string) => `/api/users/${id}/dissable`,
    token: '/api/users/update/token',
    logged: '/api/users/info/logged',
    stores: 'api/users/list/stores',
    changePassword: (id: string) => `/api/users/${id}/change/password`,
    updatePassword: (id: string) => `/api/users/${id}/update/password`
  },
  channel:{
    list: (channelQuery?: string) =>  `/api/channels${channelQuery}`,
    store: (id: string) => `/api/channels/${id}/store`,
    storeQuery: (id: string, storeQuery: string) => `/api/channels/${id}/store${storeQuery}`,
    availables: 'api/channels/list/availables',
    states: 'api/channels/list/state/availables',
    pause: (id: string) => `/api/channels/${id}/pause`,
    active: (id: string) => `/api/channels/${id}/active`,
    listTest:  `/api/channels/list/test`
  },
  instance: {
    qr: (basePath: string, id: string) => `${basePath}/get/instance/${id}`,
    delete: (basePath: string, id: string) => `${basePath}/${id}`,
    ban: 'api/banned_users',
    edit: (basePath: string, id: string) => `${basePath}/${id}`,
  },
  languages: {
    list: 'api/languages'
  },
  workflow: {
    list: '/api/order_state_workflows'
  },
  storeCategory: {
    list: '/api/store_categories',
    create: '/api/store_categories',
    details: (id: string) => `/api/store_categories/${id}`,
    edit: (id: string) => `/api/store_categories/${id}`,
    delete: (id: string) => `/api/store_categories/${id}`,
  },
  productAddonCategory: {
    list: (productAddonCategoryQuery?: string) => `/api/product_addon_categories${productAddonCategoryQuery}`,
    create: '/api/product_addon_categories',
    details: (id: string) => `/api/product_addon_categories/${id}`,
    edit: (id: string) => `/api/product_addon_categories/${id}`,
    delete: (id: string) => `/api/product_addon_categories/${id}`,
    store: (id: string) => `/api/product_addon_categories/${id}/store?length=100&order=created_at&dir=ASC`,
    name: (id: string) => `/api/product_addon_categories/${id}/name`,
    min: (id: string) => `/api/product_addon_categories/${id}/min`,
    max: (id: string) => `/api/product_addon_categories/${id}/max`,
  } as APIEndpointsProduct,
  productAddon: {
    list: '/api/product_addons',
    create: '/api/product_addons',
    edit: (id: string) => `/api/product_addons/${id}`,
    delete: (id: string) => `/api/product_addons/${id}`,
    createMultiple: '/api/product_addons/multiple',
    editMultiple: (id: string) => `/api/product_addons/multiple/${id}`,
    activate: (id: string) => `/api/product_addons/${id}/activate`,
    deactivate: (id: string) => `/api/product_addons/${id}/deactivate`,
    store: (id: string) =>  `/api/product_addons/${id}/store?light=1`,
    product: (id: number) =>  `/api/product_addons/${id}/product`,
    name: (id: number) =>  `/api/product_addons/${id}/name`,
    max: (id: number) =>  `/api/product_addons/${id}/max`,
    active: (id: number) => `/api/product_addons/${id}/toggle/active`,
    price: (id: number) =>  `/api/product_addons/${id}/price`,    
  } as APIEndpointsProduct,
  playground: {
    list: '/api/play_grounds',
    sendMessage: '/api/play_grounds/customer/message',
    details: (id: any) => `/api/play_grounds/${id}`,
    listByStore: (id: any) => `/api/play_grounds/${id}/store`,
    lastUnfishedConversation: (store: any) => `/api/play_grounds/${store}/last/unfinished/conversation`,
    resetConversation: (store: any) => `/api/play_grounds/${store}/reset/conversation` 
  },

  paymentMethods: {
    list_payment_methods: '/api/payment_methods/list/availables',
    list_payment_methods_all: '/api/payment_methods',
    list_payment_methods_stores:(id: string) => `/api/payment_methods/${id}/store`,
    details_payment_methods_wire_transfer:(id: string) => `/api/wire_transfers/${id}`,
    details_payment_methods_link_button:(id: string) => `/api/link_buttons/${id}`,
    delete_payment_methods_wire_transfer:(id: string) => `/api/wire_transfers/${id}`,
    delete_payment_methods_link_button:(id: string) => `/api/link_buttons/${id}`,  
    delete_payment_methods_cash:(id: string) => `/api/cash/${id}`,  
    update_payment_methods_wire_transfer:(id: string) => `/api/wire_transfers/${id}`,
    update_payment_methods_link_button:(id: string) => `/api/link_buttons/${id}`,   
    delete: (id: string, basePath: string) => `${basePath}/${id}`,
    update: (id: string, basePath: string) => `${basePath}/${id}`,
    deliveryToogle: (id: string) => `/api/payment_methods/${id}/delivery`,   
    takeawayToogle: (id: string) => `/api/payment_methods/${id}/takeaway`,   
    active: (id: string) => `/api/payment_methods/${id}/active`,
    dissable: (id: string) => `/api/payment_methods/${id}/dissable`,
  },
  
  conversation: {
    list: '/api/conversations',
    sendMessage: (id: string) => `/api/conversations/${id}/send/message`,
    details: (id: string) => `/api/conversations/${id}`,
    listByStore: (id: string) => `/api/conversations/${id}/store`,
    listWhatsappByStore: (id: string, query: string) => `/api/whatsapps/conversations/store/${id}${query}`,
    listWhatsappGroupedByStore : (id: string, query: string) =>  `/api/whatsapps/grouped/conversations/store/${id}${query}`,
    getConversationByUsername: (username:string, start: number = 0) => `whatsapps/conversations/username/${username}?page=${start}&length=5`,
    qualifications: '/api/conversations/list/qualifications/availables',
    setQualification: (id: string, basePath: string) => `/api/conversations/${id}${basePath}`,
    read: (id: string) => `/api/conversations/${id}/mark/read`,
    sendAudio: (id: string) => `/api/conversations/${id}/send/audio`,
    sendImage: (id: string) => `/api/conversations/${id}/send/image`,
    sendDocument: (id: string) => `/api/conversations/${id}/send/document`,
  },

  message: {
    markAsSeen: (id: string) =>  `/api/messages/${id}`,
    downloadFile: (id: string) => `/api/messages/${id}/download`,
    downloadThumbnail: (id: string) => `/api/messages/${id}/thumbnail`
  },
  bannedUsers: {
    check: (channel: string, username: string) => `/api/banned_users/channel/${channel}/username/${username}`,
    ban: '/api/banned_users',
    unban: (bannedId: string) => `/api/banned_users/${bannedId}`,
    store: (id: string) => `/api/stores/${id}/banned/list`,
    list: '/api/banned_users',
    whatsapp: (id: string)=> `/api/banned_users/${id}/whatsapp`,
  },
  dashboard: {
    data: `/api/dashboard/store`,
    admin: `/api/dashboard/admin`,
    count: '/api/dashboard/count',
    stats: (statsQuery?: string) =>  `/api/dashboard/store/admin/stats${statsQuery}`,
    downloadReport: (statsQuery?: string) =>  `/api/dashboard/store/admin/stats/csv${statsQuery}`,
    lastWeek: (storeId: string) => `/api/dashboard/${storeId}/store/last/week`,
    year: (storeId: string, year: string) => `/api/dashboard/${storeId}/store/orders/by/${year}/year`,
    averageConversationDuration: () => '/api/dashboard/count/average/conversation/duration',
    countBotMessages: () => '/api/dashboard/count/bot/messages',
    countConversations: () => '/api/dashboard/count/conversations',
    countConversationsBot: () => '/api/dashboard/count/conversations/bot',
    countConversationsFullAI: () => '/api/dashboard/count/conversations/full/ai',
    rateFullAI: () => '/api/dashboard/count/conversations/full/ai/rate',
    countCustomerMessages: () => '/api/dashboard/count/customer/messages',
    countCustomers: () => '/api/dashboard/count/customers',
    countMerchantInvolved: () => '/api/dashboard/count/merchant/involved',
    countMerchantMessages: () => '/api/dashboard/count/merchant/messages',
    countMerchantMessagesFromApp: () => '/api/dashboard/count/merchant/messages/from/app',
  },
  billingPlan:{
    list: '/api/billing',
    detailsByStoreAndDate: '/api/billing/',
    update: (storeId: string, billingPlan: string) => `/api/stores/${storeId}/billing/plan/${billingPlan}`,
    invoices: '/api/billing/invoices',
    invoicesByStates: '/api/billing/invoices/states',
    details: (id: string) => `/api/billing/invoices/${id}`,
    statesAvailables: '/api/billing/invoices/states/availables',
    updateState: (id: string, state_id: string) => `/api/billing/invoices/${id}/states/${state_id}`
  },
  storeConfiguration: {
    list: (storeId: string) => `/api/store_configuration/${storeId}`,
    edit: (storeId: string) => `/api/store_configuration/${storeId}`
  },
  customer: {
    ordersByCustomerAndChannel: (id: string, channelId: string, query: string) => `/api/customers/${id}/channel/${channelId}${query}`
  },
  productCombo: {
    list: '/api/combo',
    create: '/api/combo',
    edit: (id: string) => `/api/combo/${id}`,
    details: (id: string) => `/api/combo/${id}`,
    delete: (id: string) => `/api/combo/${id}`,
    store: (id: string) =>  `/api/combo/${id}/store`,
    storeQuery: (id: string, storeQuery: string) => `/api/combo/${id}/store${storeQuery}`,
    activate: (id: string) => `/api/combo/${id}/activate`,
    deactivate: (id: string) => `/api/combo/${id}/desactivate`,
  },
  deliveryZone: {
    list: '/api/delivery_zones',
    create: '/api/delivery_zones',
    edit: (id: string) => `/api/delivery_zones/${id}`,
    details: (id: string) => `/api/delivery_zones/${id}`,
    delete: (id: string) => `/api/delivery_zones/${id}`,    
    listStore: (id: string) => `/api/delivery_zones/${id}/store?length=100`    
  },
  whatsappTest: {
    delete: (basePath: string, id: string) => `${basePath}/test/${id}`,
    details: (basePath: string, id: string) => `${basePath}/test/${id}`,
    edit: (basePath: string, id: string) => `/api/${basePath}/test/${id}`,
    create: (basePath: string) => `${basePath}/test`,
    qr: (basePath: string, id: string) => `${basePath}/test/get/instance/${id}`,
    regenerate: (basePath: string, id: string) => `${basePath}/test/${id}/regenerate`,
    addUsernameStore: (id: string) => `/api/whatsapps/${id}/username/store`,
    removeUsernameStore: (id: string, usernameStore: string) => `/api/whatsapps/${id}/username/store/${usernameStore}`
  },
  forgotPassword: {
    restore: '/public/request/restore/password'
  },
  whatsapp: {
    list: '/api/whatsapps/default/channels',
    create: '/api/whatsapps/default/channel',
  },
  pausedUsers: {
    pause: '/api/paused_users',
    activate: (pausedId: string) => `/api/paused_users/${pausedId}`,
    update: (id: string) => `/api/paused_users/${id}`,
    check: (channel: string, username: string) => `/api/paused_users/channel/${channel}/username/${username}`,
    store: (id: string) => `/api/stores/${id}/paused/list`

  },
  variant: {
    name:(id: string) => `/api/variants/${id}/name`,
    price: (id: string) => `/api/variants/${id}/price`,
    description: (id: string) => `/api/variants/${id}/description`,
    active: (id: string) => `/api/variants/${id}/toggle/active`
  } as APIEndpointsVariant,

  plans: {
    list: '/api/plans'
  }

};
