import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useStores from 'src/hooks/use-stores';
import { set } from 'src/redux/slices/store';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFAutocomplete } from '../hook-form';

type Props = {
  onChange: Function;
};
const StoreSeach = ({ onChange }: Props) => {
  const [stores] = useStores({ light: true });
  const dispatch = useDispatch();
  const stateStore: any = useSelector((state: RootState) => state.store);
  const [currentStore, setCurrentStore] = useState(
    stateStore || (stores.length > 0 ? stores[0] : null)
  );

  const defaultValues = useMemo(
    () => ({
      store: stateStore || (stores.length > 0 ? stores[0] : null),
    }),
     
    [stateStore]
  );
  const methods = useForm<any>({
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    trigger,
    getValues,
  } = methods;

  useEffect(() => {
    if (currentStore) {
      localStorage.setItem('currentStore', JSON.stringify(currentStore));
      dispatch(set(currentStore));
    }
  }, [currentStore, dispatch]);

  useEffect(() => {
    // Actualiza el valor del formulario y dispara la validación cuando cambia el stateStore
    setValue('store', stateStore);
    trigger('store');
  }, [stateStore, setValue, trigger]);
  const onSubmit = useCallback(
    async ({ store }: any) => {
      setCurrentStore(store);
      onChange(store.id);
    },
    [onChange]
  );
  const handleAutocompleteChange = useCallback(
    (selectedOption: any) => {
      // Actualiza el valor del control con la opción seleccionada
      setValue('store', selectedOption);
      // Llama a onSubmit después de que se selecciona una opción
      onSubmit(getValues());
    },
    [getValues, onSubmit, setValue]
  );

  useEffect(() => {
    // si tenia algo almacenado lo uso, sino tomo el primer elemento
    if (!currentStore?.id && stores.length > 0) {
      handleAutocompleteChange(stores[0]);
    }
  }, [currentStore?.id, stores, setValue, handleAutocompleteChange]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {stores && (
        <RHFAutocomplete
          name="store"
          label="Comercios"
          disableClearable
          autoHighlight
          onChange={(event, value) => {
            handleAutocompleteChange(value);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={stores.map((option) => option)}
          getOptionLabel={(option: any) => option?.name || ''}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
        />
      )}
    </FormProvider>
  );
};
export default StoreSeach;
