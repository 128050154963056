import * as Yup from 'yup';
import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { IPromptItem } from 'src/types/prompt';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'src/routes/hook';
import { useResponsive } from 'src/hooks/use-responsive';
import { paths } from 'src/routes/paths';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFAutocomplete,
  RHFEditor,
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/system/Stack';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { ICountryItem } from 'src/types/location';
import { ICategoryItem } from 'src/types/category';
import { Box, Checkbox, Divider, FormControlLabel, MenuItem, Paper } from '@mui/material';
import { IStoreItem } from 'src/types/store';
import useHasRoles from 'src/hooks/use-has-roles';
import useStores from 'src/hooks/use-stores';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

type Props = {
  currentCategory?: ICategoryItem;
};
type FormValuesProps = any;

export default function CategoryNewEditForm({ currentCategory }: Props) {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();
  const stateStore: any = useSelector((state: RootState) => state.store);
  const [addons, setAddons] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const isAdmin = useHasRoles(['ROLE_ADMIN']);

  const NewCategorySchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),  
  });
  const defaultValues = useMemo(
    () => ({
      name: currentCategory?.name || '',
      store: stateStore?.id || '',
      order: currentCategory?.itemOrder || '',
      addons: currentCategory?.addonsVisibles || [],
    }),
     
    [currentCategory]
  );
  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewCategorySchema),
    defaultValues,
    mode: 'onBlur',
  });
  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;
  const getAddonsByStore = useCallback((storeId: string) => {
    axios
      .get(API_ENDPOINTS.productAddon.store(storeId))
      .then(({ data }) => {      
        setAddons(data);
      })
      .catch((error) => {});
  }, []);
  useEffect(() => {
    if (stateStore.id) {
      setValue('store', stateStore?.id);
      getAddonsByStore(stateStore.id);
    }
  }, [stateStore, setValue, getAddonsByStore]);
  useEffect(() => {
    if (currentCategory) {
      reset(defaultValues);
    }
  }, [currentCategory, defaultValues, reset]);

  const editCategory = useCallback(
    async (id: string, params: FormValuesProps) => {
      const { name, order, store, addons: formAddons } = params;
      const addons_aux = formAddons.map((addon: any)=> addon.id);
      const DATA = {
        name,
        order: parseInt(order, 10)|| 0,
        addons: addons_aux
      };
      axios
        .put(API_ENDPOINTS.category.editMultiple(id), DATA)
        .then(({ data }) => {
          enqueueSnackbar('Categoría editada con éxito');
          reset();
          router.push(paths.dashboard.category.root);
        })
        .catch((error) => {});
    },
    [enqueueSnackbar, reset, router]
  );
  const createCategory = useCallback(
    async (params: FormValuesProps) => {
      const { name, order, store, addons: formAddons } = params;
      const addons_aux = formAddons.map((addon: any)=> addon.id);

      const DATA = {
        name,
        order: parseInt(order, 10)|| 0,
        store: parseInt(store, 10),
        addons: addons_aux
      };
      axios
        .post(API_ENDPOINTS.category.createMultiple, DATA)
        .then(({ data }) => {
          enqueueSnackbar('Categoría creada con éxito');
          reset();
          router.push(paths.dashboard.category.root);
        })
        .catch((error) => {});
    },
    [enqueueSnackbar, reset, router]
  );

  const onSubmit = useCallback(
    async (params: FormValuesProps) => {
      if (currentCategory) {
        editCategory(currentCategory.id, params);
      } else {
        createCategory(params);
      }
    },
    [currentCategory, editCategory, createCategory]
  );
  const handleAutocompleteChange = useCallback(
    (selectedOption: any) => {
      // Actualiza el valor del control con la opción seleccionada
      setValue('addons', selectedOption);
      // Llama a onSubmit después de que se selecciona una opción
    },
    [setValue]
  );
  const handleToggleSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) setValue('addons', addons);
      else setValue('addons', []);
      return !prev;
    });
  };
  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Categoría
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Complete los siguientes datos...
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Detalle" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Nombre</Typography>
              <RHFTextField name="name" placeholder="Ingrese el nombre de la categoría" />
            </Stack>
           {isAdmin && 
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Orden de aparición en el Menú</Typography>
              <RHFTextField
                name="order"
                placeholder="Ingrese el orden  de aparición en el Menú de la categoría"
                helperText="Es el orden en el que esta categoría aparecerá en el menú"
                type="number"
              />
            </Stack>
           }
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">Variantes</Typography>
              <RHFAutocomplete
              limitTags={10}
                size='medium'
                name="addons"
                disableCloseOnSelect
                label="Selecciona una o más variantes para esta categoría"
                disableClearable
                autoHighlight
                groupBy={(option) => option.category_name}
                multiple
                onChange={(event, value) => {
                  handleAutocompleteChange(value);
                }}
                PaperComponent={(paperProps) => {
                  const { children, ...restPaperProps } = paperProps;
                  return (
                    <Paper {...restPaperProps}>
                      <Box
                        onMouseDown={(e) => e.preventDefault()} // prevent blur
                        pl={1.5}
                        py={0.5}
                      >
                        <FormControlLabel
                          onClick={(e) => {
                            e.preventDefault(); // prevent blur
                            handleToggleSelectAll();
                          }}
                          label="Seleccionar todos"
                          control={<Checkbox id="select-all-checkbox" checked={selectAll} />}
                        />
                      </Box>
                      <Divider />
                      {children}
                    </Paper>
                  );
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={addons.map((option) => option)}
                getOptionLabel={(option: any) => option?.name || ''}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )}
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentCategory ? 'Crear Categoría' : 'Guardar cambios'}
        </LoadingButton>
      </Grid>
    </>
  );
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
