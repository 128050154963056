import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// routes
// _mock
import { USER_STATUS_OPTIONS } from 'src/_mock';
// types
import { IUserItem } from 'src/types/user';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { CustomFile } from 'src/components/upload';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
  RHFMultiSelect,
} from 'src/components/hook-form';
import { IStoreItem, IStoreType } from 'src/types/store';
import useStores from 'src/hooks/use-stores';
import useRoles from 'src/hooks/use-roles';
import useUserStates from 'src/hooks/use-user-states';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { Checkbox, InputAdornment, Stack } from '@mui/material';
import { useRouter } from 'src/routes/hook/use-router';
import { paths } from 'src/routes/paths';
import useLocales from 'src/locales/use-locales';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  store: IStoreItem;
  currentDeliveryZone: any;
  addDeliveryZone: Function;
};

export default function QuickDeliveryZoneForm({
  open,
  onClose,
  store,
  currentDeliveryZone,
  addDeliveryZone
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();  
  const NewPaymentMethodSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    price: Yup.string().required('El precio es requerido'),
    description: Yup.string().required('La descripción es requerida'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentDeliveryZone?.name || '',
      price: currentDeliveryZone?.price || '',
      description: currentDeliveryZone?.description || '',
    }),
     
    [currentDeliveryZone, open]
  );

  const methods = useForm<any>({
    resolver: yupResolver(NewPaymentMethodSchema),
    defaultValues,
    mode: 'onBlur',
  });

  const {
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  useEffect(() => {    
    const defaults = {
      name: currentDeliveryZone?.name || '',
      price: currentDeliveryZone?.price || '',
      description: currentDeliveryZone?.description || '',
    };
    reset(defaults);
  }, [currentDeliveryZone, reset]);


  const editDeliveryZone = useCallback(
    async (params: any) => {
      const { name,  price, description} = params;
      const PARAMS: any = {
        name, price: parseFloat(price)|| 0, description
      };
      
      axios
        .put(API_ENDPOINTS.deliveryZone.edit(currentDeliveryZone.id), PARAMS)
        .then(({ data }) => {
          enqueueSnackbar('Zona de entrega editada con éxito');
          reset();
          onClose();
          // router.reload();
        })
        .catch((error) => {});
    },
    [enqueueSnackbar, reset, onClose, currentDeliveryZone]
  );

  const createDeliveryZone = useCallback(
    async (params: any) => {
      const { name, price, description } = params;    
      addDeliveryZone({name, price, description});
      reset();     
    },
    [reset, addDeliveryZone]
  );

  const onSubmit = useCallback(
    async (params: any) => {
      if (currentDeliveryZone) {
        editDeliveryZone(params);
      } else {
        createDeliveryZone(params);
      }
    },
    [currentDeliveryZone, editDeliveryZone, createDeliveryZone]
  );

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Crear Zona de entrega</DialogTitle>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)',
            }}
          >
            <Box sx={{ display: { xs: 'none', sm: 'block' } }} />
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <RHFTextField name="name" label="Nombre" />
              <RHFTextField
                    name="price"
                    type="tel"
                    label="Precio"
                    placeholder='0.00'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box component="span" sx={{ color: 'text.disabled' }}>
                            $
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
            </Stack>
            <Stack
              sx={{
                width: '100%',
              }}
              spacing={1.5}
            >
              <RHFTextField name="description" label="Descripción" rows={4} multiline/>
            </Stack>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            Cancelar
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {currentDeliveryZone ? 'Guardar cambios' : 'Agregar'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
