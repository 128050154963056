import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// routes
// _mock
// types
// assets
// components
import { Box, Chip, FormControl, InputAdornment, MenuItem, Stack, TextField } from '@mui/material';
import FormProvider, {
  RHFRadioGroup, RHFSelect
} from 'src/components/hook-form';
import { useBoolean } from 'src/hooks/use-boolean';
import { useLocales } from 'src/locales';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type Props = {
  onSelect: any;
  onClose: VoidFunction;
  open: boolean;
  store?: any;
};

export default function OrderPickUpTimeModal({ onSelect, open, onClose, store }: Props) {

  const {t} = useLocales();
  const inputRef = useRef<any>(null);
  const [pickUpTime, setpickUpTime] = useState(store?.waiting_time || '0');
  const defaultValues = useMemo(
    () => ({
      pickuptime: '0',
    }),
     
    []
  );
 
  const methods = useForm({    
    mode: 'onBlur',
    defaultValues
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
    setValue
  } = methods;

  const onSubmit = useCallback(
    async (params: any) => { 
        // esto es porque si ingresan "no indicar" se envia un 0
        const data = parseInt(pickUpTime, 10) > 0 ? pickUpTime : '0';            
        onSelect(data);
        onClose();
        reset();
    },
    [pickUpTime, onClose, reset, onSelect]
  );
  return (
    <Dialog
      // fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
      onClick={(event) => event.stopPropagation()}
    >
      <FormProvider methods={methods}  onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Seleccione el tiempo de preparación</DialogTitle>

      <DialogContent>
      <Stack spacing={1.5} sx={{ p: 2 }}>
            <FormControl
              sx={{
                flexShrink: 0
              }}
            >
              {pickUpTime >= 0 ? (
                <TextField
                  type="number"                  
                  inputProps={{ min: 1, max: 999 }}
                  label="Tiempo de preparación"
                  helperText="Usa las opciones abajo para autocompletar."
                  value={pickUpTime}
                  onChange={(ev) => {
                    const value = ev.target.value;
                    if (value.length <= 3) {
                      setpickUpTime(value);
                    }
                  }}
                  InputProps={{
                    sx: {
                      textAlign: 'center',
                      width: '150px',
                    },
                    endAdornment: (
                      <InputAdornment position="start">
                        <Box component="span">minutos</Box>
                      </InputAdornment>
                    ),
                  }}
                  inputRef={inputRef}
                  onClick={(event) => event.stopPropagation()}
                  
                />
              ) : (
                <TextField
                  label="Tiempo de preparación"
                  helperText="Usa las opciones abajo para autocompletar."
                  value="No indicar"
                  disabled
                />
              )}
            </FormControl>
            <Box
              display="grid"
              gridTemplateColumns="repeat(3, 1fr)"
              sx={{ typography: 'subtitle1' }}
              columnGap={1}
              rowGap={1}
            >
              <Chip
                key="0"
                color="primary"
                label="No indicar"
                size="small"
                variant={pickUpTime === '-1' ? 'filled' : 'soft'}
                onClick={(ev) => {
                  ev.stopPropagation();
                  setpickUpTime('-1');
                }}
              />
              <Chip
                key="15"
                color="primary"
                label="15 min"
                size="small"
                variant={pickUpTime === '15' ? 'filled' : 'soft'}
                icon={<Iconify icon="material-symbols-light:alarm-outline" />}
                onClick={(ev) => {
                  ev.stopPropagation();
                  setpickUpTime('15');
                }}
              />
              <Chip
                key="30"
                color="primary"
                label="30 min"
                size="small"
                variant={pickUpTime === '30' ? 'filled' : 'soft'}
                icon={<Iconify icon="material-symbols-light:alarm-outline" />}
                onClick={(ev) => {
                  ev.stopPropagation();
                  setpickUpTime('30');
                }}
              />
              <Chip
                key="45"
                color="primary"
                label="45 min"
                size="small"
                variant={pickUpTime === '45' ? 'filled' : 'soft'}
                icon={<Iconify icon="material-symbols-light:alarm-outline" />}
                onClick={(ev) => {
                  ev.stopPropagation();
                  setpickUpTime('45');
                }}
              />
              <Chip
                key="60"
                label="60 min"
                color="primary"
                size="small"
                variant={pickUpTime === '60' ? 'filled' : 'soft'}
                icon={<Iconify icon="material-symbols-light:alarm-outline" />}
                onClick={(ev) => {
                  ev.stopPropagation();
                  setpickUpTime('60');
                }}
              />
              <Chip
                key="custom"
                color="primary"
                label="Personalizado"
                size="small"
                variant={pickUpTime === '' ? 'filled' : 'soft'}
                onClick={(ev) => {
                  ev.stopPropagation();
                  setpickUpTime('');
                  inputRef.current.focus();
                }}
              />
            </Box>
          </Stack>
       
      </DialogContent>

      <DialogActions>
        <Button variant="contained"  type="submit" color="primary">
          Enviar
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
