import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import { FormControlLabel, Stack, Switch } from '@mui/material';
import FormProvider, {
  RHFSelect,
  RHFTextField
} from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import useLocales from 'src/locales/use-locales';
import { IStoreItem } from 'src/types/store';
import axios, { API_ENDPOINTS } from 'src/utils/axios';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  store: IStoreItem;
  currentPaymentMethod: any;
};

export default function PaymentMethodsQuickInstanceForm({
  open,
  onClose,
  store,
  currentPaymentMethod
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLocales();
  const NewPaymentMethodSchema = Yup.object().shape({
    enterprise: Yup.string().required('El nombre de la empresa es requerido'),
    cbu: Yup.string().when(['paymentMethod'], ([paymentMethod]) =>
      paymentMethod === 'payement-method-wire-transfer' ? Yup.string().required('El número de cuenta es requerido') : Yup.string()
    ),
    link: Yup.string().when(['paymentMethod'], ([paymentMethod]) =>
      paymentMethod === 'payement-method-link-button' ? Yup.string().required('El link de pago es requerido') : Yup.string())
  });

  const defaultValues = useMemo(
    () => ({
      paymentMethod: currentPaymentMethod?.class || '',
      cbu: currentPaymentMethod?.cbu || '',
      link: currentPaymentMethod?.link || '',
      enterprise: currentPaymentMethod?.enterprise || currentPaymentMethod?.decription,
      delivery: currentPaymentMethod?.delivery !== undefined ? currentPaymentMethod?.delivery : true,
      takeaway: currentPaymentMethod?.takeaway !== undefined ? currentPaymentMethod?.takeaway : true,
    }),
     
    [currentPaymentMethod, open]
  );


  const methods = useForm<any>({
    resolver: yupResolver(NewPaymentMethodSchema),
    defaultValues,
    mode: 'onBlur',
  });
  const [paymentMethods, setpaymentMethods] = useState<any[]>([]);

  const {
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const selectedPaymentMethod = watch('paymentMethod');


  useEffect(() => {
    const defaults = {
      paymentMethod: currentPaymentMethod?.class || '',
      cbu: currentPaymentMethod?.cbu || '',
      link: currentPaymentMethod?.link || '',
      enterprise: currentPaymentMethod?.enterprise || currentPaymentMethod?.description,
      description: currentPaymentMethod?.description || '',
      delivery: currentPaymentMethod?.delivery !== undefined ? currentPaymentMethod?.delivery : true,
      takeaway: currentPaymentMethod?.takeaway !== undefined ? currentPaymentMethod?.takeaway : true,
    }
    reset(defaults)
  }, [currentPaymentMethod, reset]);

  const getMethodsList = () => {
    axios
      .get(API_ENDPOINTS.paymentMethods.list_payment_methods)
      .then(({ data }) => {        
        setpaymentMethods(data);
      })
      .catch((error) => { });
  };
  useEffect(() => {
    if (store?.id) {
      getMethodsList();
    }
  }, [store]);
  const editPaymentMethod = useCallback(
    async (params: any) => {
      const { cbu, link, enterprise, paymentMethod,delivery, takeaway } = params;
      const BASE_PATH = paymentMethods.filter((option) => option.id === paymentMethod)[0].base_path;
      let PARAMS: any = {
        enterprise,
        store: parseInt(store.id, 10),
        delivery,
        takeaway
      }
      switch (paymentMethod) {
        case 'payement-method-link-button':
          PARAMS = {
            ...PARAMS,
            link
          }
          break;
        case 'payement-method-wire-transfer':
          PARAMS = {
            ...PARAMS,
            cbu
          }
          break;

        default:
          break;
      }

      axios
        .put(`${BASE_PATH}/${currentPaymentMethod.id}`, PARAMS)
        .then(({ data }) => {
          enqueueSnackbar('Metodo de pago actualizado con éxito');
          reset();
          onClose();
          // router.reload();
        })
        .catch((error) => {

        });
    },
    [enqueueSnackbar, reset, onClose, store, paymentMethods, currentPaymentMethod],
  );

  const createPaymentMethod = useCallback(
    async (params: any) => {
      const { cbu, link, enterprise, paymentMethod, delivery, takeaway } = params;
      const BASE_PATH = paymentMethods.filter((option) => option.id === paymentMethod)[0].base_path;
      let PARAMS: any = {
        enterprise,
        store: parseInt(store.id, 10),
        delivery,
        takeaway
      }
      switch (paymentMethod) {
        case 'payement-method-link-button':
          PARAMS = {
            ...PARAMS,
            link
          }
          break;
        case 'payement-method-wire-transfer':
          PARAMS = {
            ...PARAMS,
            cbu
          }
          break;

        default:
          break;
      }

      axios
        .post(BASE_PATH, PARAMS)
        .then(({ data }) => {
          enqueueSnackbar('Metodo de pago creado con éxito');
          reset();
          onClose();
          // router.reload();
        })
        .catch((error) => {

        });
    },
    [enqueueSnackbar, reset, onClose, store, paymentMethods],
  );

  const onSubmit = useCallback(
    async (params: any) => {
      if (currentPaymentMethod) {
        editPaymentMethod(params)
      } else {
        createPaymentMethod(params);
      }
    },
    [currentPaymentMethod, editPaymentMethod, createPaymentMethod]
  );

  const renderLinkButtonForm = (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
      <RHFTextField name="link" label="Ingrese el link de Pago" />
    </Stack>
  );
  const renderWireTransferForm = (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
      <RHFTextField name="cbu" label="Ingrese el número de cuenta" />
    </Stack>
  );

  // payement-method-debit-card
  // payement-method-credit-card
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{selectedPaymentMethod ? 'Editar' : 'Crear'} Método de Pago</DialogTitle>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)',
            }}
          >
            <Box sx={{ display: { xs: 'none', sm: 'block' } }} />
            <Stack
              sx={{
                width: '100%',
              }}
              spacing={1.5}
            >
              <RHFSelect
                label="Seleccione un método de pago"
                InputLabelProps={{ shrink: watch().paymentMethod }}
                name="paymentMethod"
              >
                {paymentMethods &&
                  paymentMethods.map((option) => (
                    <MenuItem key={option.name} value={option.id}>
                      {t(`paymentMethod.${option.name}`)}
                    </MenuItem>
                  ))}
              </RHFSelect>
            
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              {watch().paymentMethod === 'payement-method-cash' ? (
                <RHFTextField name="enterprise" label="Moneda" />
              ) : (
                <RHFTextField name="enterprise" label="Nombre de la empresa" />
              )}
            </Stack>

            {watch().paymentMethod === 'payement-method-link-button' && renderLinkButtonForm}
            {watch().paymentMethod === 'payement-method-wire-transfer' && renderWireTransferForm}
            <Stack
              sx={{
                width: '100%',
              }}
              spacing={1.5}
              direction="row"
            >
        {/*
        <FormControlLabel
          control={<Switch color="primary" name="delivery" checked={watch().delivery} onChange={(e) => methods.setValue('delivery', e.target.checked)} />}
          label="Delivery"
        />
        <FormControlLabel
          control={<Switch color="primary" name="takeaway" checked={watch().takeaway} onChange={(e) => methods.setValue('takeaway', e.target.checked)} />}
          label="Retiro en tienda"
        />
        */}
      
            </Stack> 
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={() => {
            onClose()
          }}>
            Cancelar
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {currentPaymentMethod ? 'Guardar cambios' : 'Agregar'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
